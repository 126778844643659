import {useEffect, useState} from 'react'

import { useNavigate } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import moment from 'moment'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { formatPhoneNumber } from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import RevolutCheckout from "@revolut/checkout";

import NavigatorBar from '../navigator_bar';
import Footer from '../footer';
import DynamicSize from '../functions/dynamic_size';
import { act } from 'react-dom/test-utils';

function Part1(user) {
    const navigate = useNavigate()
    async function signOut() {
        
        try {
          await Auth.signOut();
          navigate('/')
        } catch (error) {
          console.log('error signing out: ', error);
        }
    }
    return(
        <div style={{
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            //flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '10vh',
            
        }} >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '2%',
                paddingRight: '2%'
            }} >
                <h3 style={{
                    color: '#257062',
                    fontSize: DynamicSize({large: 60, medium: 40, mobile: 20})
                }} >
                    Välkommen {user.FIRST_NAME === 'PLACEHOLDER' ? null : user.FIRST_NAME}
                </h3>
                <p>Det här är din profil - här hanterar du din prenumeration</p>
            <div style={{
                //position: 'absolute',
                position: DynamicSize({large: 'absolute', medium: 'absolute', mobile: 'relative'}),
                right: DynamicSize({large: '5%', medium: '5%', mobile: null}),
                width: DynamicSize({large: '10%', medium: '10%', mobile: '50%'})
            }} >
                <div 
                    style={{
                        backgroundColor: '#257062',
                        borderRadius: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        boxShadow: `1px 2px 5px #000`,
                    }}
                    onClick={() => signOut()} >
                    <p style={{
                        color: 'white',
                        marginLeft: '10%',
                        marginRight: '10%'
                    }} >
                        Logga ut
                    </p>
                </div>
            </div>
            </div>
        </div>
    )
}

function Part2({user, orders, FetchUser}) {
    const [showSkipOneOrder, setShowSkipOneOrder] = useState(true)
    const [showCancelOrder, setShowCancelOrder] = useState(true)
    const [loadingPayment, setLoadingPayment] = useState(false)
    const [updatedPaymentMethod, setUpdatedPaymentMethod] = useState(false)
    const cardPayment = user.PAYMENT_PROVIDER === 'REVOLUT' ? true : false
    const [activeOrderId, setActiveOrderId] = useState()
    const [cardInformation, setCardInformation] = useState()

    async function FetchCardInformation() {
        if (!cardPayment) {
            return
        }
        const url = 'https://ys3t2pknoik3tmt26dit3nwatm0hvkle.lambda-url.eu-north-1.on.aws/'
        const response = await fetch(
            url
                +'?customer_id='+user.REVOLUT_CUSTOMER_ID
        )
        const json = await response.json()
        console.log('j',json)
        setCardInformation(json.payment_methods[0].method_details.last4)
    }

    useEffect(() => {
        FetchCardInformation()
    }, [])

    async function UpdateCardPayment() {
        async function UpdateOrder() {
            try {
                await API.graphql({
                    query: mutations.updateOrder,
                    variables: {
                        input: {
                            id: activeOrderId,
                            PAYMENT_PROVIDER: 'REVOLUT'
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
                })
            } catch (error) {
                console.log(error)
            }
        } 

        try {
            setLoadingPayment(true)
            const createcustomerURL = 'https://6wazzgtdug3sjhboeqka3xt56i0pkixh.lambda-url.eu-north-1.on.aws/' //prod
            const createcustomer = await fetch(
                createcustomerURL
                    +'?email='+user.EMAIL
                    +'&provider=REVOLUT'
            );
            const createcustomerJSON = await createcustomer.json()
            console.log(createcustomerJSON)
            if (createcustomerJSON.approved) {
                const url = 'https://znepylxyprl75fg4yhkmmxgkea0iwlrz.lambda-url.eu-north-1.on.aws/' //prod
                const response = await fetch(
                    url
                        +'?amount='+0
                        +'&currency=SEK'
                        +'&customer_id='+createcustomerJSON.response
                        +'&provider=REVOLUT'
                );
                console.log(response)
                const json = await response.json();
                console.log(json)
                return(
                    <script>
                        {RevolutCheckout(json.public_id, "sandbox").then(function (instance) {
                            setLoadingPayment(false)
                            instance.payWithPopup({
                                locale: "sv",
                                email: user.EMAIL,
                                savePaymentMethodFor: 'merchant',
                                onSuccess() {
                                    setUpdatedPaymentMethod(true)
                                    UpdateOrder()
                                    FetchUser()
                                    window.alert("Tack, din betalmetod är nu uppdaterad!");
                                },
                                onError(message) {
                                    window.alert(message);
                                },
                            });
                        })}
                </script>)
            }
            else {
                return
            }
        } catch (error) {
            console.log('error in UpdateCardPayment', error)
        }
    }

    async function SkipOneOrder(order) {
        try {
            const newOrderDate = moment(order.ORDER_DATE).add(order.RECURRING, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            await API.graphql({
                query: mutations.updateOrder,
                variables: {
                    input: {
                        id: order.id,
                        ORDER_DATE: newOrderDate,
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            })
            FetchUser()
            setShowSkipOneOrder(false)
        } catch (error) {
            console.log(error)
        }
    }

    async function CancelOrder(order) {
        await API.graphql({
            query: mutations.updateOrder,
            variables: {
                input: {
                    id: order.id,
                    STATUS: 'CANCELLED'
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        FetchUser()
        setShowCancelOrder(false)
    }

    async function ActivateOrder(order) {
        await API.graphql({
            query: mutations.updateOrder,
            variables: {
                input: {
                    id: order.id,
                    STATUS: 'CREATED'
                }
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })
        FetchUser()
        setShowCancelOrder(false)
    }
    
    function Button({text, active, onClick}) {
        if (!text) {
            return(null)
        }
        return(
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 20,
                backgroundColor: active ? '#257062' : 'transparent',
                borderStyle: active ? 'none' : 'solid',
                borderWidth: 1,
                cursor: onClick ? 'pointer' : null
            }}
            onClick={onClick} >
                <p style={{
                    color: active ? 'white' : 'red',
                    fontWeight: 'bold',
                    margin: 0,
                    marginLeft: 10,
                    marginRight: 10
                }} >
                    {text}
                </p>
            </div>
        )
    }

    function DisplayActiveOrder({order}) {
        if (!order || !order.RECURRING) {
            return
        }
        const activeOrder = order.STATUS === 'CANCELLED' ? false : true
        const cardPayment = order.PAYMENT_PROVIDER === 'REVOLUT' ? true : false
        setActiveOrderId(order.id)
        console.log(order)
            return(
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }} >
                    
                    <div style={{
                        //width: cardPayment ? '20%' : '50%',
                        paddingRight: cardPayment ? null : 20
                    }} > 
                        {cardPayment ? 
                            <Button 
                                text={activeOrder ? 'Aktiv' : 'Inaktiv'}    
                                active={activeOrder}
                                />
                        : 
                        <Button
                           text={'Strax aktiv - inväntar sista info'} 
                           active={false} />
                    }
                    </div>
                    {cardPayment ?
                        <div >
                            <p style={{
                                margin: 0
                            }} >
                                Nya rakblad var {order.RECURRING/7}:e vecka
                            </p>

                        </div>
                    : null
                }
                    <div style={{
                        //width: '20%', 
                    }} > 
                        <Button
                            text={activeOrder ? 'Ändra' : null}
                            active={activeOrder}
                            onClick={() => console.log('hej')} />
                    </div>
                    <div style={{
                        //width: '20%', 
                    }} > 
                        <Button
                            text={activeOrder ? 'Inaktivera' : 'Aktivera'}
                            active={!activeOrder}
                            onClick={() => activeOrder ? CancelOrder(order) : ActivateOrder(order)} />
                    </div>
                </div>
           /*      <div style={{
                    width: '40vw',
                    direction: 'flex',
                }} >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                    }} >
                    
                        <div>
                            
                        </div>
                        <div style={{
                            width: '20vw'
                        }} >
                            <h3>
                                {order.Products.items[0].product.NAME}
                            </h3>
                            <p>
                                {order.AMOUNT} SEK
                            </p>
                        </div>
                        <div style={{
                            width: '20vw'
                        }} >
                            <h3>
                                Nästa leverans
                            </h3>
                            <p>
                                {moment(order.ORDER_DATE).format('YYYY-MM-DD')}
                            </p>
                            
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        }} >
                        <div style={{
                            width: '20vw'
                        }} >
                            <div 
                                onClick={() => showSkipOneOrder ? SkipOneOrder(order) : null}
                                style={{
                                    backgroundColor: showSkipOneOrder ? '#257062' : 'grey',
                                    borderRadius: '10px',
                                    width: '50%',
                                    cursor: showSkipOneOrder ? 'pointer' : null
                                }} >
                                <p style={{
                                    fontSize: '13px',
                                    textAlign: 'center',
                                    margin: 0,
                                    paddingTop: 3
                                }} >
                                    Hoppa över
                                </p>
                                <p style={{
                                    fontSize: '13px',
                                    textAlign: 'center',
                                    margin: 0,
                                    paddingBottom: 3
                                }} >
                                    nästa leverans
                                </p>
                            </div>
                        </div>
                        <div style={{
                            width: '20vw'
                        }} >
                            <div 
                                onClick={() => showCancelOrder ? CancelOrder(order) : null}
                                style={{
                                    backgroundColor: showCancelOrder ? '#257062' : 'grey',
                                    borderRadius: '10px',
                                    width: '50%',
                                    cursor: showCancelOrder ? 'pointer' : null
                                }}
                                >
                                <p style={{
                                    fontSize: '13px',
                                    textAlign: 'center',
                                    margin: 0,
                                    paddingTop: 3
                                }} >
                                    Avsluta
                                </p>
                                <p style={{
                                    fontSize: '13px',
                                    textAlign: 'center',
                                    margin: 0,
                                    paddingBottom: 3
                                }} >
                                    prenumeration
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */
            )
        
    }

    function DisplayRecentOrder({order}) {
        if (!order || order.RECURRING > 0 ) {
            return
        }
        return(
            <div>
                {order.Products.items.map(product => (

                
                <div style={{
                    display: 'flex'
                }} >

                    <div style={{
                        width: '60%'
                    }} >
                        <p>
                            {product.product.NAME}
                        </p>

                    </div>
                    <div style={{
                        width: '20%'
                    }} >
                        <div>
                            <p>
                                {moment(order.ORDER_DATE).format('YYYY-MM-DD')}
                            </p>
                        </div>

                    </div>
                    <div style={{
                        width: '20%'
                    }} >
                        <div>
                            <p>
                                {product.product.PRICE}
                            </p>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        )
    }

    function Header({text}) {
        return(
            <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
            }} >
                <h3>
                    {text}
                </h3>
                <div style={{
                    height: 1,
                    backgroundColor: '#257062',
                    flex: 1,
                    marginLeft: 10
                }} />
                
            </div>
        )
    }


    return(
        <div style={{
            width: DynamicSize({large: '40vw', medium: '40vw', mobile: '100vw'}),
            maxWidth: 1000,
        }} >
            <div style={{
                marginBottom: 20
            }} > 
                <Header text={'Min prenumeration'} />
                {orders.map(order => 
                    <DisplayActiveOrder order={order} key={order.id} />)}
            </div>

            <div style={{
                marginBottom: 20
            }} >
                <Header text={'Min betalningsinformation'} />
                <p>
                    Betalmetod
                </p>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }} >
                    {cardPayment ? 
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }} >
                            <div>
                                <p style={{
                                    margin: 0
                                }} >
                                    Betalkort XXXX XXXX {cardInformation}
                                </p>
                            </div>
                            <div style={{
                                width: '20%'
                            }} >
                                <Button
                                    text={'Ändra'}
                                    active={true} />
                            </div>
                        </div>
                    :
                    <div style={{
                        
                    }}  >
                        <Button 
                            text={'Klicka här för att fylla i uppgifter och aktivera'}
                            active={false}
                            onClick={() => UpdateCardPayment()} /> 
                    </div>
                }

                </div>

            </div>


            <div>
                <Header text={'Tidigare köp'} />
                <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }} >
                <div style={{
                    width: '60%'
                }} >
                    <div>
                        <p>
                            Produkt
                        </p>

                    </div>
                </div>
                <div style={{
                    width: '20%'
                }} >
                    <div>
                        <p>
                            Datum
                        </p>
                    </div>

                </div>
                <div style={{
                    width: '20%'
                }} >
                    <div>
                        <p>
                            Pris
                        </p>
                    </div>

                </div>
            </div>
            {orders.map(order => 
                <DisplayRecentOrder order={order} key={order.id} />
            )}
            </div>
        </div>
    )
    
}

function Part3({user, FetchUser}) {
    const [notValidPhoneNumber, setNotValidPhoneNumber] = useState()
    const [updatedCredentials, setUpdatedCredentials] = useState(false)

    const [country, setCountry] = useState('Sverige')
    const [firstName, setFirstName] = useState(user.FIRST_NAME === 'PLACEHOLDER' ? '' : user.FIRST_NAME)
    const [lastName, setLastName] = useState(user.LAST_NAME === 'PLACEHOLDER' ? '' : user.LAST_NAME)
    const [address, setAddress] = useState(user.STREET_ADDRESS === 'PLACEHOLDER' ? '' : user.STREET_ADDRESS)
    const [apartmentNumber, setApartmentNumber] = useState(user.APARTMENT_NUMBER === null ? '' : user.APARTMENT_NUMBER)
    const [postNumber, setPostNumber] = useState(user.POST_NUMBER === 12345 ? '' : user.POST_NUMBER)
    const [city, setCity] = useState(user.CITY === 'PLACEHOLDER' ? '' : user.CITY)
    const [phoneNumber, setPhoneNumber] = useState(user.PHONE_NUMBER)

    function InputField({text, value, setValue, columns, type, prefix, validInput, disabled, autocomplete}) {
        //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
        return(
            <div style={{
                borderStyle: 'solid',
                borderColor: 
                    (text !== 'Lägenhetsnr, våning, etc. (frivillig)' 
                    && value.length < 1) || validInput 
                        ? 'red' : 'black',
                borderRadius: '10px',
                width: columns > 1 ? '48%' : '100%',
                height: '50px',
                display: 'flex',
                paddingTop: '5px',
                paddingBottom: '5px',
                marginBottom: '20px'
            }} >
                {prefix ? 
                    <PhoneInput
                    style={{
                        width: '90%',
                        fontSize: 20,
                        marginLeft: 10,
                        borderColor: 'transparent',
                        outline: 'none'
                    }}
                    defaultCountry="SE"
                    placeholder="Telefonnummer"
                    value={value}
                    onChange={setValue}/>
                : <input style={{
                    width: prefix ?  '80%' : '95%',
                    //borderStyle: 'solid',
                    borderColor: 'transparent',
                    //borderRadius: '10px',
                    outline: 'none',
                    paddingLeft: '10px',
                    fontSize: '20px',
                    }} 
                    type={type}
                    placeholder={text}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={disabled ? true : false}
                    autoComplete={autocomplete}
                    />}
                
            </div>
        )
    }

    async function UpdateUserCredentials() {
        try {
            if (!isValidPhoneNumber(phoneNumber)) {
                setNotValidPhoneNumber(true)
                return
            }
            const input = {
                id: user.id,
                STREET_ADDRESS: address,
                APARTMENT_NUMBER: apartmentNumber === '' ? null : apartmentNumber,
                POST_NUMBER: postNumber === '' ? 12345 : postNumber ,
                CITY: city,
                PHONE_NUMBER: phoneNumber,
                FIRST_NAME: firstName,
                LAST_NAME: lastName
            }
            console.log(input)
            await API.graphql({
                query: mutations.updateCustomer,
                variables: {
                    input: input
                },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            })
            setNotValidPhoneNumber(false)
            setUpdatedCredentials(false)
            FetchUser()
        } catch (error) {
            if (error.errors[0].message.includes('PHONE_NUMBER')) {
                setNotValidPhoneNumber(true)
            }
            console.log('error', error)
        }

    }

    useEffect(() => {
        setUpdatedCredentials(true)
        if (!user || 
            typeof(address) == 'undefined' ||
            typeof(apartmentNumber) == 'undefined' ||
            typeof(postNumber) == 'undefined' ||
            typeof(city) == 'undefined' ||
            typeof(phoneNumber) == 'undefined' ||
            typeof(firstName) == 'undefined' ||
            typeof(lastName) == 'undefined') {
                return
            }
            if (address == user.STREET_ADDRESS &&
                (apartmentNumber === user.APARTMENT_NUMBER || (apartmentNumber === '' && !user.APARTMENT_NUMBER ))&&
                postNumber === user.POST_NUMBER &&
                city === user.CITY &&
                phoneNumber === user.PHONE_NUMBER &&
                firstName === user.FIRST_NAME &&
                lastName === user.LAST_NAME
                ) {
                    return
                }
        if (address.length < 1 ||
            postNumber.length < 1 ||
            city.length < 1 ||
            phoneNumber.length < 1 ||
            firstName.length < 1 ||
            lastName.length <1 ) {
                setUpdatedCredentials(false)
                return
            }
        setUpdatedCredentials(true)
    }, [address, apartmentNumber, postNumber, city, phoneNumber, firstName, lastName])

    return( 
        <div style={{
            marginLeft: DynamicSize({large: '5vw', medium: '5vw', mobile: null}),
            display: 'flex',
            justifyContent: 'space-evenly'
        }} >
            <div style={{
                width: DynamicSize({large: '40vw', medium: '40vw', mobile: '90vw'}),
                maxWidth: 1000,
            }} >
                <div style={{
                    display: 'flex', 
                    justifyContent: 'space-between',
                    marginBottom: 10
                }} >
                    <h3>
                        Leveransinformation
                    </h3>
                    {user.PAYMENT_PROVIDER === 'REVOLUT' 
                        && user.FIRST_NAME !== 'PLACEHOLDER'
                        && user.LAST_NAME !== 'PLACEHOLDER'
                        && user.STREET_ADDRESS !== 'PLACEHOLDER'
                        && user.POST_NUMBER !== 12345
                        && user.CITY !== 'PLACEHOLDER'
                        
                        ?
                    null :
                    <div style={{
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderRadius: 20,
                        paddingLeft: 10,
                        paddingRight: 10,
                        backgroundColor: 'yellow'
                    }} >
                        <p style={{
                            margin: 0
                        }} >
                            Fyll i informationen  - så vi skickar rätt
                        </p>
                    </div>
                }

                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }} >
                    {InputField({
                        text: 'Namn',
                        value: firstName,
                        setValue: setFirstName,
                        columns: 2,
                        type: 'text',
                        autocomplete: 'given-name'
                        })}
                    {InputField({
                    text: 'Efternamn',
                    value: lastName,
                    setValue: setLastName,
                    columns: 2,
                    type: 'text',
                    autocomplete: 'family-name'
                    })}
                </div>
                {InputField({
                    text: 'Land',
                    value: country,
                    setValue: setCountry,
                    columns: 1,
                    type: 'text',
                    disabled: true
                    })}
                {InputField({
                    text: 'Adress',
                    value: address,
                    setValue: setAddress,
                    columns: 1,
                    type: 'text',
                    autocomplete: 'street-address'
                    })}
                {InputField({
                    text: 'Lägenhetsnr, våning, etc. (frivillig)',
                    value: apartmentNumber,
                    setValue: setApartmentNumber,
                    columns: 1,
                    type: 'text'
                    })}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }} >
                    {InputField({
                        text: 'Postnummer',
                        value: postNumber,
                        setValue: setPostNumber,
                        columns: 2,
                        type: 'text',
                        autocomplete: 'postal-code',
                        })}
                    {InputField({
                    text: 'Stad',
                    value: city,
                    setValue: setCity,
                    columns: 2,
                    type: 'text',
                    autocomplete: 'address-level1'
                    })}
                </div>
                {InputField({
                    text: 'Telefonnummer',
                    value: phoneNumber,
                    setValue: setPhoneNumber,
                    columns: 1,
                    type: 'tel',
                    prefix: true,
                    validInput: notValidPhoneNumber,
                    autocomplete: 'tel-national'
                    })}
                <div 
                    onClick={() => updatedCredentials ? UpdateUserCredentials() : null}
                    style={{
                        backgroundColor: updatedCredentials ? '#257062' : 'grey',
                        width: '100%',
                        height: '60px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: updatedCredentials ? 'pointer' : null,
                        boxShadow: `1px 2px 5px #000`,
                }} >
                    <p style={{
                        color: 'white'
                    }} >
                        SPARA
                    </p>
                </div>
            </div>
        </div>
    )
}

function Content({
    user, 
    orders, 
    FetchUser,
  
}) {
    return(
        <div>
            <Part1 user={user} />
                <div style={{
                    display: 'flex',
                    flexDirection: DynamicSize({large: 'row', medium: 'row', mobile: 'column'}),
                    justifyContent: DynamicSize({large: 'space-evenly', medium: 'space-evenly', mobile: 'center'}),
                }} >
                    <Part2 user={user} orders={orders} FetchUser={FetchUser} />
                    <Part3 user={user} FetchUser={FetchUser} />

                </div>
        </div>
    )
}

function UserPage() {
    const navigate = useNavigate()
    const [user, setUser] = useState()
    const [orders, setOrders] = useState()

    const [email, setEmail] = useState()
    const [newsLetter, setNewsLetter] = useState(true)

    async function FetchOrders(customerID) {
        try {
            const orders = await (await API.graphql({
                query: queries.ordersByCustomerIDAndORDER_DATE,
                variables: {
                    customerID: customerID,
                    limit: 10,
                    sortDirection: "ASC" }
                    //,authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            })).data.ordersByCustomerIDAndORDER_DATE.items
            const ordersWithProducts = await Promise.all(orders.map(async order => {
                const fetchProduct = await (await API.graphql({ query: queries.getProduct, variables: {id: order.Products.items[0].productId} })).data.getProduct
                order.Products.items[0].product = fetchProduct
                return order
              }))
              console.log(ordersWithProducts)
            setOrders(ordersWithProducts)
        } catch (error) {
            console.log('error in FetchOrders, UserPage', error)
        }
    }

    async function FetchUser() {
        try {
            const authUser = await Auth.currentAuthenticatedUser()
            const u = await (await API.graphql({
                query: queries.customersByEMAIL,
                variables: { 
                    EMAIL: authUser.attributes.email 
                },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            })).data.customersByEMAIL.items[0]
            FetchOrders(u.id)
            setUser(u)
        } catch (error) {
            console.log('error in FetchUser, UserPage', error)
        }
    }

    useEffect(() => {
        FetchUser()
    }, [])

    if (!user || !orders) {
        return null
    }
  
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
        }} >
            <NavigatorBar />
            <Content 
                user={user} 
                orders={orders} 
                FetchUser={FetchUser}
                 />
            <Footer />
        </div>
    );
}

export default withAuthenticator(UserPage);