import {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
import { API, Storage } from "aws-amplify";
import * as queries from '../graphql/queries'
import moment from 'moment';
import SimpleNavigatorBar from './simple_navigator_bar';
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

import visa_logo from '../assets/Visa_Brandmark_Blue_RGB_2021.svg'
import mastercard_logo from '../assets/mc_symbol.svg'
import postnord_logo from '../assets/postnord_logo.svg'
import { HiOutlineMail, HiScale } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import './order.css';
import RevolutCheckout from "@revolut/checkout";
import DynamicSize from './functions/dynamic_size';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { formatPhoneNumber } from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { type } from '@testing-library/user-event/dist/type';

const createcustomer_url = 'https://6wazzgtdug3sjhboeqka3xt56i0pkixh.lambda-url.eu-north-1.on.aws/' //prod
const webpayment_url = 'https://znepylxyprl75fg4yhkmmxgkea0iwlrz.lambda-url.eu-north-1.on.aws/' //prod
const revolut_checkout_mode = 'prod'

/* const createcustomer_url = 'https://oc4yvckxkzewtx47hfytwof5cu0oayfr.lambda-url.eu-north-1.on.aws/' //dev
const webpayment_url = 'https://qowjphxi3bgshbrgek26pmxjvm0odflr.lambda-url.eu-north-1.on.aws/' //dev
const revolut_checkout_mode = 'sandbox' //dev */

function InputField({text, value, setValue, columns, type, prefix, validInput, disabled, autocomplete}) {
    //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
    return(
        <div style={{
            borderStyle: 'solid',
            borderColor: validInput ? 'red' : 'black',
            borderRadius: '10px',
            width: columns > 1 ? '48%' : '100%',
            height: '50px',
            display: 'flex',
            paddingTop: '5px',
            paddingBottom: '5px',
            marginBottom: '20px'
        }} >
            {prefix ? 
                <PhoneInput
                style={{
                    width: '90%',
                    fontSize: 20,
                    marginLeft: 10,
                    borderColor: 'transparent',
                    outline: 'none'
                }}
                defaultCountry="SE"
                placeholder="Telefonnummer"
                value={value}
                onChange={setValue}/>
            : 
            <input style={{
                width: prefix ?  '80%' : '95%',
                //borderStyle: 'solid',
                borderColor: 'transparent',
                //borderRadius: '10px',
                outline: 'none',
                paddingLeft: '10px',
                fontSize: '20px',
                }} 
                type={type}
                placeholder={text}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={disabled ? true : false}
                autoComplete={autocomplete}
                />}
        </div>
    )
}

function UseDiscountCode({setDiscount, setValueCode, setValidDiscountCode, totalPrice}) {
    const [discountCode, setDiscountCode] = useState('')
    const [validatedDiscountCode, setValidatedDiscountCode] = useState(false) 
    const codes = [
        {code: 'Lansering23', value: 'Två extra rakblad'},
        {code: 'Oktober23', value: 'Två extra rakblad'},
        {code: 'Prenumerering', discount: 1, value: 'Prenumerering'}
    ]
    function AddDiscountCode() {
        codes.map(code => {
            if (discountCode === code.code) {
                if (code.discount) {
                    if (totalPrice > 99) {
                        return
                    }
                    setDiscount(1-code.discount)
                    setValidatedDiscountCode(true)
                }
                if (code.value) {
                    setValueCode(code.value)
                    setValidatedDiscountCode(true)
                }
            }
        })
        if (!validatedDiscountCode) {
            setValidDiscountCode(validatedDiscountCode)
        }
    }
    return(
        <div >
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10
            }} >
                <p>
                    Rabattkod
                </p>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderColor: 'black',
                    borderStyle: 'solid',
                    borderRadius: 10,
                    height: 40,
                    borderWidth: 0.5
                }} >
                    <input style={{
                        borderColor: 'transparent',
                        outline: 'none',
                        borderRadius: 10
                    }}
                    type={'text'}
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    >
                    </input>
                    <div style={{
                        backgroundColor: 'black',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        height: 40,
                    }} 
                    onClick={() => AddDiscountCode()} >
                        <p style={{
                            color: 'white',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }} >
                            Lägg till
                        </p>
                    </div>
                </div>
            </div>
        </div>


    )
}

function Credentials({
        cookie, 
        setCookie, 
        removeCookie, 
        nextDelivery, 
        productIDs, 
        futureProductIDs, 
        futureTotalPrice, 
        loadingPayment, 
        setLoadingPayment, 
        totalPrice,
        valueCode
    }) {
    const navigate = useNavigate()
    const width = useWindowWidth()
    const breakingPoint = 1200 //change to dynamic size
    const mobileBreakingPoint = 500
    
    const [email, setEmail] = useState(cookie['customer:email'] ? cookie['customer:email'] : '')
    const [newsLetter, setNewsLetter] = useState(true)
    const [country, setCountry] = useState('Sverige') //useState(cookie['customer:country'] ? cookie['customer:country'] : '')
    const [firstName, setFirstName] = useState(cookie['customer:firstName'] ? cookie['customer:firstName'] : '')
    const [lastName, setLastName] = useState(cookie['customer:lastName'] ? cookie['customer:lastName'] : '')
    const [address, setAddress] = useState(cookie['customer:address'] ? cookie['customer:address'] : '')
    const [apartmentNumber, setApartmentNumber] = useState(cookie['customer:apartmentNumber'] ? cookie['customer:apartmentNumber'] : '')
    const [postNumber, setPostNumber] = useState(cookie['customer:postNumber'] ? cookie['customer:postNumber'] : '')
    const [city, setCity] = useState(cookie['customer:city'] ? cookie['customer:city'] : '')
    const [phoneNumber, setPhoneNumber] = useState(cookie['customer:phoneNumber'] ? cookie['customer:phoneNumber'] : '')
    const [notValidPhoneNumber, setNotValidPhoneNumber] = useState(false)

    useEffect(() => {
        console.log(Number.isInteger(Number(apartmentNumber)), apartmentNumber)
    }, [apartmentNumber])

    async function CreateCustomer({email, provider, firstName, lastName, address, postNumber, country, phoneNumber, apartmentNumber, city, newsLetter}) {
        try {
            console.log(phoneNumber, phoneNumber.substring(1), isValidPhoneNumber(phoneNumber))
            if (!isValidPhoneNumber(phoneNumber)) {
                setNotValidPhoneNumber(true)
                return
            }
            const adjustedPhoneNumber = phoneNumber.substring(1)
            //const url = 'https://6wazzgtdug3sjhboeqka3xt56i0pkixh.lambda-url.eu-north-1.on.aws/' //prod

            const response = await fetch(
                createcustomer_url
                    +'?email='+email
                    +'&provider='+provider
                    +'&firstName='+firstName
                    +'&address='+address
                    +'&postNumber='+postNumber
                    +'&country='+country
                    +'&phoneNumber='+adjustedPhoneNumber
                    +'&city='+city
                    +'&lastName='+lastName
                    +'&apartmentNumber='+apartmentNumber
                    +'&newsLetter='+newsLetter
            );
            const json = await response.json()
            console.log('customer', json)
            if (json.approved) {
                return(json.response)
            }
            else if (json.response.includes('PHONE_NUMBER')) {
                setNotValidPhoneNumber(true)
                return(null)
            }
            else {
                return(null)
            }
        } catch (error) {
            console.log('error in CreateCustomer', error)
        }
    }
    
    async function Pay({amount, currency}) {
        try {
            setLoadingPayment(true)
            const customer_id = await CreateCustomer({
                email: email,
                provider: 'REVOLUT',
                firstName: firstName,
                lastName: lastName,
                address: address, 
                postNumber: postNumber.replace(/\s+/g, ''),
                country: country,
                phoneNumber: phoneNumber,
                apartmentNumber: Number.isInteger(Number(apartmentNumber)) ? apartmentNumber : '',
                city: city,
                newsLetter: newsLetter,
            })
            if (!customer_id) {
                setLoadingPayment(false)
                return
            }
            const orderDate = JSON.stringify(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')).replace('+', '%2b')
            const nextOrderDate = JSON.stringify(moment().add(Math.round(nextDelivery), 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')).replace('+', '%2b')
            //const url = 'https://znepylxyprl75fg4yhkmmxgkea0iwlrz.lambda-url.eu-north-1.on.aws/' //prod
            
            const response = await fetch(
                webpayment_url
                    +'?amount='+amount
                    +'&currency='+currency
                    +'&customer_id='+customer_id
                    +'&provider=REVOLUT'
                    +'&products='+JSON.stringify(productIDs)
                    +'&future_products='+JSON.stringify(futureProductIDs)
                    +'&order_date='+orderDate
                    +'&frequency='+nextOrderDate
                    +'&future_price='+futureTotalPrice
                    +'&recurring='+nextDelivery
                    +'&value_code='+valueCode
            );
            const json = await response.json();
            console.log('paid', response, json)
            return(
                <script>
                    {RevolutCheckout(json.token, revolut_checkout_mode).then(function (instance) {
                        setLoadingPayment(false)
                        instance.payWithPopup({
                            locale: "sv",
                            name: firstName+' '+lastName,
                            email: email,
                            savePaymentMethodFor: 'merchant',
                            onSuccess() {
                                removeCookie('product', {path: '/'})
                                removeCookie('shoppingBag', {path: '/'})
                                removeCookie('futureShoppingBag', {path: '/'})
                                setCookie('customer:email', email , {path: '/'})
                                setCookie('customer:country', country , {path: '/'})
                                setCookie('customer:firstName', firstName , {path: '/'})
                                setCookie('customer:lastName', lastName , {path: '/'})
                                setCookie('customer:address', address , {path: '/'})
                                setCookie('customer:apartmentNumber', apartmentNumber , {path: '/'})
                                setCookie('customer:postNumber', postNumber , {path: '/'})
                                setCookie('customer:city', city , {path: '/'})
                                setCookie('customer:phoneNumber', phoneNumber , {path: '/'})
                                navigate('/')
                                //window.alert("Thank you!");
                            },
                            onError(message) {
                                window.alert(message);
                            },
                        });
                    })}
            </script>)
        } catch (error) {
        }
    }

  /*   function FastCheckout({public_id}) {
        const RC = RevolutCheckout(public_id)

        const paymentRequest = RC.paymentRequest({
            target: document.getElementById('revolut-payment-request'),
            requestShipping: true,
            shippingOptions,
            onShippingOptionChange: (selectedShippingOption) => {
                // ideally compute new total price. calls can be made to a server here
                return Promise.resolve({
                status: 'success',
                total: {
                    amount: 777 + selectedShippingOption.amount,
                },
                })
            },
            onShippingAddressChange: (selectedShippingAddress) => {
                // ideally compute new total price. calls can be made to a server here
                const newShippingOption = {
                id: 'new-shipping',
                label: 'The new ultra-fast method',
                amount: 500,
                description: 'The shipping method faster than lightening',
                }

                return Promise.resolve({
                status: 'success',
                shippingOptions: [newShippingOption, ...shippingOptions],
                total: {
                    amount: 777 + newShippingOption.amount,
                },
                })
            },
            onSuccess() {
                setResult('Paid')
            },
            onError(error) {
                setResult(`Error: ${error.message}`)
            },
            buttonStyle: { size: 'small', variant: 'light-outlined' },
        })

        paymentRequest.canMakePayment().then((result) => {
        if (result) {
            paymentRequest.render()
        } else {
            setResult('Not supported')
            paymentRequest.destroy()
        }
        })
    } */

    return(
        <div style={{
            width: width < breakingPoint ? width < mobileBreakingPoint ? '95vw' : '70vw' : '40vw',
            maxWidth: 1000
        }} >
            <h3>
                Kontaktinformation
            </h3>
            {InputField({
                text: 'Mail',
                value: email,
                setValue: setEmail,
                columns: 1,
                type: 'email',
                autocomplete: 'email'
                })}
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }} >
                <input
                    type={'checkbox'}
                    value={newsLetter}
                    defaultChecked={true}
                    onChange={(e) => setNewsLetter(e.target.checked)}
                    style={{
                        transform: "scale(1)",
                        marginRight: '10px'
                    }}
                    />
                    <h3>
                        Skicka mail med nyheter & erbjudanden
                    </h3>
            </div>
            <h3>
                Leveransadress
            </h3>
            {InputField({
                text: 'Land',
                value: country,
                setValue: setCountry,
                columns: 1,
                type: 'text',
                disabled: true
                })}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                
            }} >
                {InputField({
                    text: 'Namn',
                    value: firstName,
                    setValue: setFirstName,
                    columns: 2,
                    type: 'text',
                    autocomplete: 'given-name'
                    })}
                    {InputField({
                    text: 'Efternamn',
                    value: lastName,
                    setValue: setLastName,
                    columns: 2,
                    type: 'text',
                    autocomplete: 'family-name'
                    })}
            </div>
            {InputField({
                text: 'Adress',
                value: address,
                setValue: setAddress,
                columns: 1,
                type: 'text',
                autocomplete: 'street-address'
                })}
            {InputField({
                text: 'Lägenhetsnr, våning, etc. (frivillig)',
                value: apartmentNumber,
                setValue: setApartmentNumber,
                columns: 1,
                type: 'text'
                })}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
            }} >
                {InputField({
                    text: 'Postnummer',
                    value: postNumber,
                    setValue: setPostNumber,
                    columns: 2,
                    type: 'text',
                    autocomplete: 'postal-code'
                    })}
                {InputField({
                text: 'Stad',
                value: city,
                setValue: setCity,
                columns: 2,
                type: 'text',
                autocomplete: 'address-level1'
                })}
            </div>
            {InputField({
                text: 'Telefonnummer',
                value: phoneNumber,
                setValue: setPhoneNumber,
                columns: 1,
                type: 'text',
                prefix: true,
                validInput: notValidPhoneNumber,
                autocomplete: 'tel-national'
                })}
            {totalPrice > -0.1 ? 
                <div 
                    onClick={() => loadingPayment ? null : Pay({amount: totalPrice, currency: 'SEK'})}
                    style={{
                        backgroundColor: 'black',
                        width: '100%',
                        height: '60px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        boxShadow: `1px 2px 5px #000`,
                    }} >
                    <p style={{
                        color: 'white'
                    }} >
                        BETALA
                    </p>
                </div>
            : null } 
        </div>
    )
}

function Checkout() {
    const navigate = useNavigate()
    const [selectedProduct, setSelectedProduct] = useState()
    const [shoppingBag, setShoppingBag] = useState()
    const [displayShoppingBag, setDisplayShoppingBag] = useState([])
    const [productIDs, setProductIDs] = useState()
    const [futureShoppingBag, setFutureShoppingBag] = useState()
    const [displayFutureShoppingBag, setDisplayFutureShoppingBag] = useState([])
    const [futureProductIDs, setFutureProductIDs] = useState()
    const [totalPrice, setTotalPrice] = useState()
    const [discount, setDiscount] = useState(1)
    const [valueCode, setValueCode] = useState('')
    const [validDiscountCode, setValidDiscountCode] = useState(true)
    const [futureTotalPrice, setFutureTotalPrice] = useState(0)
    const [nextDelivery, setNextDelivery] = useState()
    const [loadingPayment, setLoadingPayment] = useState(false)
    
    const [cookie, setCookie, removeCookie] = useCookies([
        'shoppingBag', 
        'futureShoppingBag', 
        'product',
        'customer:email',
        'customer:country',
        'customer:firstName',
        'customer:lastName',
        'customer:address',
        'customer:apartmentNumber',
        'customer:postNumber',
        'customer:city',
        'customer:phoneNumber'
    ]) 
    const width = useWindowWidth()
    const breakingPoint = 1200
    const mobileBreakingPoint = 500

    function CalculatePrice({product}) {
        let price = 0
        product.map(p => price = price + p.PRICE)
        return(price)
    }
    
    async function GetShoppingBag() {
        try {
            let price = 0
            let productIDs = []
            let displayItems = []
            if (cookie.shoppingBag) {
                const shoppingBag = await Promise.all(cookie.shoppingBag.map(async product => {
                    const fetchProduct = await (
                        await API.graphql({
                            query: queries.getProduct,
                            variables: { id: JSON.parse(product).product }
                        })).data.getProduct
                        const photo = await Storage.get('assets/'+fetchProduct.S3FILE, {expires: 86400})
                        if (!productIDs.includes(fetchProduct.id)) {
                            displayItems = displayItems.length>0 ? [...displayItems, {data: fetchProduct, photo: photo}] : [{data: fetchProduct, photo: photo}]
                    }
                    productIDs.push(fetchProduct.id)
                    return fetchProduct
                }))
                price = price + CalculatePrice({product: shoppingBag})
                setShoppingBag(shoppingBag)
                setDisplayShoppingBag(displayItems)
            }
            setProductIDs(productIDs)
            let futurePrice = 0
            let futureProductIDs = []
            let futureDisplayItems = []
            if (cookie.futureShoppingBag) {
                if (cookie.futureShoppingBag.length > 0) {
                    const futureShoppingBag = await Promise.all(cookie.futureShoppingBag.map(async product => {
                        const fetchProduct = await (await API.graphql({
                            query: queries.getProduct,
                            variables: { id: JSON.parse(product).product }
                        })).data.getProduct
                        const photo = await Storage.get('assets/'+fetchProduct.S3FILE, {expires: 86400})
                        if (!futureProductIDs.includes(fetchProduct.id)) {
                            futureDisplayItems = futureDisplayItems.length>0 ? [...futureDisplayItems[0], {data: fetchProduct, photo: photo}] : [{data: fetchProduct, photo: photo}]
                        }
                        futureProductIDs.push(fetchProduct.id)
                        return fetchProduct
                    }))
                    futurePrice = futurePrice + CalculatePrice({product: futureShoppingBag})
                    setFutureShoppingBag(futureShoppingBag)
                    setNextDelivery(JSON.parse(cookie.futureShoppingBag[0]).frequency/((productIDs.includes(futureProductIDs[0]) ? 2 : 6))*2)    
                }
            }
            setFutureProductIDs(futureProductIDs)
            setTotalPrice(price)
            setFutureTotalPrice(futurePrice)
            setDisplayFutureShoppingBag(futureDisplayItems)
        } catch (error) {
            console.log('error in GetShoppingBag', error)
        }
    }
    
    useEffect(() => {
        console.log('c', cookie)
       /*  if (cookie.product) {
            if (cookie.product.length > 0) {
                if (!cookie.shoppingBag) {
                    setCookie('shoppingBag', cookie.product, {path: '/'})
                }
                else if (cookie.shoppingBag.length < 1) {
                    setCookie('shoppingBag', cookie.product, {path: '/'})
                }
            }
        } */
        GetShoppingBag()
        
        /* const script = document.createElement("script")
        script.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js"
        document.body.appendChild(script)
        return() => {
            document.body.removeChild(script)
        } */
    }, [cookie])
    
    function ProductBox({id}) {
        function changeOpacity(e) {
            if (id === selectedProduct) {
                e.target.style.opacity = '100%';
                return;
            }
            e.target.style.opacity = e.type === 'mouseover' ? '100%' : '50%'
        }
        return(
            <div 
                className='box' 
                onMouseOver={changeOpacity} 
                onMouseLeave={changeOpacity} 
                onClick={() => setSelectedProduct(id)}
                style={{
                    borderColor: id === selectedProduct ? 'red' : 'black'
                }}
                >
                 <img 
                    src="../assets/razor.png" 
                    className='product--image' 
                    alt="razor"
                    onClick={() => navigate('/skagg/')}
                    />
                
            </div>
        )
    }

    function Order() {
        function ShoppingBag({product, photo}) {
            const quantity = shoppingBag.filter((bag) => bag.id === product.id).length
            function ChangeShoppingBag({add}) {
                try {
                    const beforeChange = shoppingBag
                    
                    if (add) {
                        const addProduct = {
                            product: product.id,
                            frequency: 0
                        }
                        beforeChange.push(product)
                        setCookie('shoppingBag', cookie.shoppingBag ? [...cookie.shoppingBag, JSON.stringify(addProduct)] : [JSON.stringify(addProduct)], {path: '/'})
                        setTotalPrice(totalPrice + product.PRICE)
                    }
                    else {
                        if (quantity < 1) {
                            if (cookie.product.length < 1) {
                                removeCookie('futureShoppingBag', {path: '/'})
                            }
                            return
                        }
                        beforeChange.splice(beforeChange.findIndex((i) => i.id === product.id), 1)
                        cookie.shoppingBag.splice(cookie.shoppingBag.findIndex((i) => JSON.parse(i).product === product.id), 1)
                        setCookie('shoppingBag', cookie.shoppingBag, {path: '/'})
                        setTotalPrice(totalPrice - product.PRICE)
                        if (quantity == 1) {
                            setCookie('product', cookie.product.filter((p) => JSON.parse(p).product !== product.id), {path: '/'})
                            GetShoppingBag()
                        }
                    }
                    setShoppingBag(beforeChange)
                } catch (error) {
                }
            }
            
                return(
                    <div style={{
                        //height: 200,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '1vh'
                    }} >
                        <div style={{
                            height: DynamicSize({large: '10vw', medium: '15vw', mobile: '25vw'}),
                            width: DynamicSize({large: '10vw', medium: '15vw', mobile: '25vw'}),
                        }} >
                            <img 
                            src={photo}
                            style={{
                                height: '100%',
                                objectFit: 'contain'
                            }}
                             />
                        </div>
                        <div style={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start'
                        }} >
                            <h3 style={{
                                fontSize: DynamicSize({large: 20, medium: 20, mobile: 16}),
                            }} >
                                {product.NAME}
                            </h3>
                            <h3 style={{
                                fontWeight: 'lighter',
                                fontSize: DynamicSize({large: 20, medium: 20, mobile: 16}),
                            }} >
                                {product.DESCRIPTION}
                            </h3>
                        </div>
                        
                        <div style={{
                            width: '20%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between'
                        }} >
                            <h3 style={{
                                fontSize: DynamicSize({large: 20, medium: 20, mobile: 16}),
                            }} >
                                {product.PRICE} SEK
                            </h3>
                            <div style={{
                                marginRight: DynamicSize({large: 0, medium: 5, mobile: 10}),
                            }} >
                                <h3 
                                    onClick={() => ChangeShoppingBag({add: true})}
                                    style={{
                                        cursor: 'pointer'
                                    }} >
                                    +
                                </h3>
                                
                                <h3 >
                                    {quantity}
                                </h3>
                                <h3 
                                    onClick={() => ChangeShoppingBag({add: false})}
                                    style={{
                                        cursor: 'pointer'
                                    }} >
                                    -
                                </h3>

                            </div>
                        </div>
                        
                    </div>
                )
        }
        function FutureShoppingBag({product, photo}) {
            return(
                <div style={{
                    //height: '30%',
                    width: '100%',
                    display: 'flex',
                    //alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '1vh',
                    marginBottom: '1vh'
                }} >
                    <div style={{
                            height: DynamicSize({large: '10vw', medium: '15vw', mobile: '25vw'}),
                            width: DynamicSize({large: '10vw', medium: '15vw', mobile: '25vw'}),
                        }} >
                        <img 
                        src={photo}
                        style={{
                            height: '100%',
                            objectFit: 'contain'

                        }} />
                    </div>
                    <div style={{
                        //width: '70%',
                        marginLeft: '5%'
                    }} >
                        <h3 style={{
                            fontSize: DynamicSize({large: 20, medium: 20, mobile: 16}),
                        }} >
                            {product.NAME}
                        </h3>
                        <h3 style={{
                            fontWeight: 'lighter',
                            fontSize: DynamicSize({large: 20, medium: 20, mobile: 16}),
                        }} >
                            Nästa leverans kommer vara den {moment().add(Math.round(nextDelivery), 'days').format('DD/MM')}.
                            Du kommer debiteras {product.PRICE} SEK
                        </h3>
                    </div>
                </div>
            )
        }
        if (displayShoppingBag.length < 1) {
            return(
                <h3>Inget i varukorgen</h3>
            )
        }
        return(
            <div style={{
                width: width < breakingPoint ? width < mobileBreakingPoint ? '95vw' : '70vw' : '40vw',
                maxWidth: 1000,
                //minWidth: 500,
                marginBottom: width < breakingPoint ? '10vh' : 0
            }} >
                <h3>
                    Skickas idag
                </h3>
                <div style={{
                    height: '2px',
                    width: '100%',
                    backgroundColor: 'grey',
                    marginTop: '1vh'
                }} />
                
                {displayShoppingBag.map(bag => 
                    <ShoppingBag key={bag.data.id} product={bag.data} photo={bag.photo} />
                )}
                {typeof(nextDelivery) === 'undefined' ? null :
                    <h3 style={{
                        marginTop: '2vh'
                    }} >
                        Skickas om {(nextDelivery/7).toFixed(0)} veckor
                    </h3>
                }
                <div style={{
                    height: '2px',
                    width: '100%',
                    backgroundColor: 'grey',
                    marginTop: '1vh'
                }} />

                    {displayFutureShoppingBag.map(bag => 
                        <FutureShoppingBag key={bag.data.id} product={bag.data} photo={bag.photo} />    
                    )}

                <div style={{
                    height: '2px',
                    width: '100%',
                    backgroundColor: 'grey',
                }} />
               
                <UseDiscountCode 
                    setDiscount={setDiscount}
                    setValueCode={setValueCode}
                    setValidDiscountCode={setValidDiscountCode}
                    totalPrice={totalPrice} />
                {discount === 1 && valueCode === '' ?
                    validDiscountCode ? null 
                        :
                        <div>
                            <p>
                                Ej giltig rabattkod
                            </p>
                        </div>
                        :
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }} >
                            <p>
                                Rabattkod tillagd
                            </p>
                            {
                                valueCode === '' ? null :
                                <p>
                                    {valueCode}
                                </p>
                            }
                            {
                                discount === 1 ? null :
                                <p>
                                    {(-totalPrice*(1-discount)).toFixed(1)+' SEK'}
                                </p>
                            }
                    </div>
                }

                <div style={{
                    width: '100%',
                    marginTop: '2vh'
                }} >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }} >
                        <h3>
                            Totalt (inklusive moms)
                        </h3>
                        <h3>
                            {(totalPrice*discount).toFixed(1)} SEK
                        </h3>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '1vh'
                    }} >
                        <h3>
                            Leverans
                        </h3>
                        <div>
                            <h3>
                                Gratis med
                            </h3>
                            <img 
                                    src={postnord_logo} 
                                    alt="postnord"
                                    style={{
                                        height: 20,
                                        marginRight: 10
                                    }} />

                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '1vh'
                    }} >
                        <h3>
                            Säker betalning
                            
                        </h3>
                        <div style={{
                            display: 'flex', 
                            alignItems: 'center'
                        }} >
                            <img 
                                src={visa_logo} 
                                alt="visa payment"
                                style={{
                                    height: 20,
                                    marginRight: 10
                                }} />
                            <img 
                                src={mastercard_logo} 
                                alt="visa payment"
                                style={{
                                    height: 30
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    if (!shoppingBag || (!futureShoppingBag && cookie.futureShoppingBag)) {
        console.log('no', futureShoppingBag, cookie)
        return(    
            <SimpleNavigatorBar />
        )
    }

    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
          }}>
            {loadingPayment ? 
            <div style={{
                display: 'flex',
                position: 'fixed',
                justifyContent: 'center',
                backgroundColor: 'grey',
                opacity: 0.5,
                width: '100%',
                height: '100%',
            }} >
            <Spinner style={{
                top: '35vh',
            }} 
            size={20} />
            </div>
        : null}

        <SimpleNavigatorBar />
        
        <div style={{
            display: 'flex',
            flexDirection: width < breakingPoint ? 'column' : 'row',
            alignItems: width < breakingPoint ? 'center' : 'flex-start',
            justifyContent: 'space-evenly',
            marginTop: '10vh',
            marginBottom: '10vh'
        }} >
            <Order />
            <Credentials 
                cookie={cookie} 
                setCookie={setCookie}
                removeCookie={removeCookie}
                nextDelivery={nextDelivery} 
                productIDs={productIDs} 
                futureProductIDs={futureProductIDs}
                futureTotalPrice={futureTotalPrice}
                loadingPayment={loadingPayment}
                setLoadingPayment={setLoadingPayment}
                totalPrice={(totalPrice*discount).toFixed(1)}
                valueCode={valueCode} />
        </div>
        </div>
    );
}
export default Checkout;