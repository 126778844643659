import { useEffect } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import logo from '../assets/skagg_logo.svg';
//import logo from './logo.svg';

import { Navigate, useNavigate } from 'react-router-dom';
import '../App.css';

function SimpleNavigatorBar() {
    const navigate = useNavigate()
    
    function OnClick() {
        navigate('/')
    }

    return(
        <div 
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1vh'           
            }}
            >
            <img 
                src={logo} 
                alt="Skägg"
                style={{
                    height: '6vw',
                    maxHeight: 50,
                    cursor: 'pointer'
                }}
                onClick={() => OnClick()}
                />
       
        </div>
        
    )
};
export default SimpleNavigatorBar;