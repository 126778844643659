import {useEffect, useState} from 'react'
import logo from '../assets/SVALL_LOGO.svg';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { API } from "aws-amplify";
import * as queries from '../graphql/queries'

import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
  } from '@react-hook/window-size'

import SimpleNavigatorBar from './simple_navigator_bar';

function Frequency() {
    const navigate = useNavigate()
    const [products, setProducts] = useState()
    const [selectedProduct, setSelectedProduct] = useState()
    const [selectedFrequency, setSelectedFrequency] = useState()
    const [cookie, setCookie, removeCookie] = useCookies(['shoppingBag', 'product', 'futureShoppingBag'])
    const width = useWindowWidth()
    const breakingPoint = 1200
    const mobileBreakingPoint = 500

    async function GetProducts() {
        const fetchProducts = await API.graphql({
            query: queries.listProducts, variables: {filter: {CATEGORY: {eq: "SUBSCRIPTION"}}}
          });
          setProducts(fetchProducts.data.listProducts.items)
          setSelectedProduct(fetchProducts.data.listProducts.items[0].id)
          setSelectedFrequency(45)

    }

    useEffect(() => {
        GetProducts()
        setSelectedFrequency()
    }, [])

    function Click() {
        const product = {
            product: selectedProduct,
            frequency: selectedFrequency
        }
        console.log(cookie)
        let changeOrder = false
        let editFutureShoppingBag = []
        
        /* if (cookie.shoppingBag) {
            cookie.futureShoppingBag.map(f => {
                editFutureShoppingBag.push(f)
            })
            cookie.shoppingBag.map(s => {
                cookie.product.map(p => {
                    if (JSON.parse(s).product === JSON.parse(p).product) {
                        //change only the futureshopppingbag if neccesary
                        cookie.futureShoppingBag.map(f => {
                            if (JSON.parse(f).product === selectedProduct) {
                                const index = editFutureShoppingBag.findIndex((product) => JSON.parse(product).product === JSON.parse(f).product) 
                                editFutureShoppingBag[0] = JSON.stringify(product)
                                setCookie('futureShoppingBag', editFutureShoppingBag, {path: '/'})
                                changeOrder = true
                                return;
                            }
                            else {

                            }
                        })
                    }
                })
            })
            setCookie('product', cookie.product ? [...cookie.product.filter((c) => JSON.parse(c).product !== product.product), JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'})
        } */

        console.log('new order', cookie)
        if (!changeOrder) {
            setCookie('shoppingBag', cookie.product, {path: '/'})
            //setCookie('product', cookie.product ? [...cookie.product.filter((c) => JSON.parse(c).product !== product.product), JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'}) //see if this is needed here
            setCookie('futureShoppingBag', [JSON.stringify(product)], {path: '/'})
            //setCookie('futureShoppingBag', cookie.futureShoppingBag ? [...cookie.futureShoppingBag, JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'})
        }
        navigate('/checkout')
    }

    function CheckBox({size, checked, setChecked}) {
        return(
            <div style={{
                width: '10%',

            }} >
                <div style={{
                    height: size,
                    width: size,
                    borderStyle: 'solid',
                    borderRadius: size/2,
                    borderWidth: 0.5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    //marginLeft: '5%'
                }} >
                    <div style={{
                            height: size*0.7,
                            width: size*0.7,
                            borderRadius: size*0.7/2,
                            backgroundColor: checked ? 'black' : 'white'
                        }} />
                </div>
            </div>
        )
    }

    function OptionBox({id, header, text, frequency}) {
        function changeOpacity(e) {
            if (frequency === selectedFrequency) {
                e.target.style.opacity = '100%';
                return;
            }
            e.target.style.opacity = e.type === 'mouseover' ? '100%' : '50%'
        }
        function OnClick() {
                setSelectedProduct(id)
                setSelectedFrequency(frequency)
        }

        return(
            <div 
                onMouseOver={changeOpacity} 
                onMouseLeave={changeOpacity} 
                onClick={() => OnClick()}
                style={{
                    borderColor: frequency === selectedFrequency ? 'black' : 'grey',
                    width: '100%',
                    minWidth: '300',
                    //height: '10',
                    minHeight: '100',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    margin: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:'space-evenly',
                    cursor: 'pointer'
                }}
                >
                    <CheckBox size={20} checked={frequency === selectedFrequency ? true : false}  />
                    <div style={{
                        width: '70%',
                        //marginLeft: '5%',
                    }} >
                        <h3 style={{
                            margin: 10
                        }} >
                            {header}
                        </h3>
                        <p style={{
                            margin: 10,
                        }}
                        >{text}</p>

                    </div>
                
            </div>
        )
    }

    if (!products) {
        return null
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            //justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
        }} >
            <SimpleNavigatorBar />
            
            <div style={{
                width: width < breakingPoint ? width < mobileBreakingPoint ? '90vw' : '70vw' : '40vw',
                //minWidth: 400,
                maxWidth: 500,
                display: 'flex',
                flexDirection: 'column'
            }} >
            
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                
                }} >
                <p style={{
                    maxWidth: '100%',
                    
                }} >
                    Steg 2/3
                </p>
                <p>
                    Vi uppmuntrar alltid till skärpa. Vi kommer skicka sex sylvassa nya rakblad för {products[0].PRICE} kr så ofta du behöver. Ändra frekvens eller pausa leveranser när du vill. Frakten ingår alltid!
                </p>
                <OptionBox 
                    id={products[0].id} 
                    frequency={42}
                    header={'För dig som rakar dig flera gånger i veckan'}
                    text={'Vi skickar nya sylvassa rakblad var sjätte vecka'} />
                <OptionBox 
                    id={products[0].id}
                    frequency={63}
                    header={'För dig som rakar dig någon gång i veckan'}
                    text={'Vi skickar nya sylvassa rakblad var nionde vecka'} />
            </div>
            <div 
                onClick={() => Click()}
                style={{
                    backgroundColor: '#257062',
                    width: '100%',
                    marginTop: 15,
                    height: 70,
                    display: 'flex',
                    alignSelf: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: `1px 2px 5px #000`,
                    cursor: 'pointer',
                }} >
                <p style={{
                    color: 'white',
                    fontSize: 20
                }} >Nästa steg</p>
            </div>
            </div>
        </div>
    );
}
export default Frequency;