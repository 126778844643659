import { useCookies } from 'react-cookie';
import logo from '../assets/skagg_logo.svg';
import { AiOutlineUser } from 'react-icons/ai'
import { BsBagDash } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import DynamicSize from './functions/dynamic_size'
import '../App.css';

function NavigatorBar() {
    const navigate = useNavigate()
    const [cookie, setCookie, removeCookie] = useCookies(['shoppingBag', 'futureShoppingBag', 'product'])

    function OnClick() {
        navigate('/')
    }
    const navigatorData = [
        {
            title: 'Produkter',
            onClick: () => navigate('/products', 
                {state: 
                    {types: 
                        [
                            {CATEGORY: "START_KIT", HEADER: "Startkit", TEXT: "Rakhyvel + 2x rakblad"},
                            {CATEGORY: "SUBSCRIPTION", HEADER: "Tilläggsprodukter", TEXT: "Rakbladsprenumeration"}
                        ]
                    }
                }
            )
        },
        {
            title: 'Rakguide',
            onClick: () => navigate('/how-to-shave')
        },
        {
            title: 'Hur funkar det',
            onClick: () => navigate('/how-it-works')
        }/* ,
        {
            title: 'Tipsa en vän',
            onClick: () => navigate('/refer-a-friend')
        } */
    ]

    function Navigators({title, onClick, index}) {
        return(
            <h3 style={{        
                    marginLeft: index === 0 ? 'auto' : DynamicSize({large: 60, medium: '2vw', mobile: '1vw'}),
                    marginRight: index === navigatorData.length-1 ? 'auto' : DynamicSize({large: 60, medium: '2vw', mobile: '1vw'}),
                    marginTop: '0.5vw',
                    marginBottom: '0.5vw',
                    fontSize:  DynamicSize({large: 22, medium: 18, mobile: 14}),
                    textTransform: 'uppercase',
                    fontFamily: 'Prompt',
                    fontWeight: '200',
                    whiteSpace: 'nowrap',
                    color: '#3d3f41',
                    cursor: 'pointer'
                }}
                onClick={onClick}
            >
                {title}
            </h3>
        )   
    }

    return(
        <div 
            //className="nav--container"
            style={{
                display: 'flex',
                flexDirection: 'column'     
            }}
            >
            <div style={{
                display: 'flex',
                backgroundColor: '#3d3f41',
                alignItems: 'center',
                justifyContent: 'space-between',
                //paddingTop: '0.2vmin',
                //paddingBottom: '0.2vmin',
                paddingLeft: 30,
                paddingRight: 30
            }} >
                <img
                    src={logo}
                    className="white-svg"
                    alt='Skägg'
                    style={{
                        height: DynamicSize({large: 40, medium: 30, mobile: 20}),
                    }}
                />
                <h3 style={{
                    color: 'white',
                    fontSize: '40px',
                    fontFamily: 'Prompt',
                    fontWeight: '200',
                    cursor: 'pointer'
                }}
                onClick={() => OnClick()}
                >
                    Skägg
                </h3>
                <div style={{
                        position: 'relative', 
                        paddingTop: 10
                        }} >
                    <AiOutlineUser 
                        style={{
                            cursor: 'pointer'
                        }}
                        color={'white'} 
                        size={DynamicSize({large: '30px', medium: '25px', mobile: '20px'})}
                        onClick={() => navigate('/user')} />
                    {cookie.shoppingBag?.length > 0 ?
                        <div style={{
                            backgroundColor: 'black',
                            height: DynamicSize({large: 20, medium: 15, mobile: 15}),
                            width: DynamicSize({large: 20, medium: 15, mobile: 15}),
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-end',
                            borderStyle: 'solid',
                            borderColor: 'white',
                            borderWidth: DynamicSize({large: 2, medium: 1, mobile: 1}),
                            position: 'absolute',
                            top: DynamicSize({large: -2, medium: -1, mobile: 0}),
                            right: DynamicSize({large: -10, medium: -8, mobile: -8}),
                        }} >
                            <h3 style={{
                                color: 'white',
                                fontSize: 10
                            }} >
                                {cookie.shoppingBag.length}
                            </h3>
                        </div>
                        : null }
                        <BsBagDash 
                            color={'white'} 
                            size={DynamicSize({large: '30px', medium: '25px', mobile: '20px'})}  
                            onClick={() => cookie.shoppingBag?.length > 0 ? navigate('/checkout') : null}
                            style={{
                                marginLeft: 20,
                                cursor: 'pointer'
                            }} />
                </div>
            
            </div>
            <div 
            className='scrollbar'
            style={{
                display: 'flex',
                width: '100vw',
                maxWidth: '100%',
                overflowX: 'scroll',
                scrollbarWidth: 0,
                borderStyle: 'solid',
                borderBottomWidth: 1,
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
            }} >
                {
                    navigatorData.map((data, index) => 
                        <Navigators 
                            title={data.title}
                            onClick={data.onClick}
                            index={index}
                            key={data.title}
                             />
                    )
                }
            </div>
        </div>
    )
};
export default NavigatorBar;