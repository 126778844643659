import { useState } from 'react'
import { Analytics } from 'aws-amplify';

//import { HiOutlineMail } from 'react-icons/hi';
import { AiFillFacebook} from 'react-icons/ai';
import { AiFillInstagram} from 'react-icons/ai';
import { AiFillLinkedin} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import {
    //useWindowSize,
    useWindowWidth,
    //useWindowHeight,
} from '@react-hook/window-size';

import DynamicSize from './functions/dynamic_size';
import visa_logo from '../assets/Visa_Brandmark_Blue_RGB_2021.svg'
import mastercard_logo from '../assets/mc_symbol.svg'
import postnord_logo from '../assets/postnord_logo.svg'
import metal_recycles_forever_logo from '../assets/metal-recycles-forever-logo-white.svg'

function Footer() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  
  const width = useWindowWidth()
  const breakingPoint = 800
  const mobileBreakingPoint = 500

  function Links({header, links}) {
    return(
        <div 
            style={{
            //marginRight: 100
        }} >
            <p style={{
                color: 'white',
                fontWeight: 'bold',
            }} >
                {header}
            </p>
            {links.map(link => 
                <div 
                    key={link.name}
                    onClick={() => navigate(link.link)} >
                    <p 
                    style={{
                        color: 'white',
                        cursor: 'pointer'
                    }} >
                        {link.name}
                    </p>
                </div>
                
            )}
        </div>
    )
  }
  async function Subscribe() {
    await Analytics.updateEndpoint({
        address: email,
        channelType: 'EMAIL',
        optOut: 'NONE'
    }).then(() => {})
    setEmail('')
  } 

  return (
      <footer style={{
           //height: '20vmin',
           backgroundColor: '#3d3f41',
           marginTop: '5vw',
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           width: '100%',
           //padding: '1%',
        }} >
        <div style={{
            width: '80vw',
            maxWidth: 1000,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: DynamicSize({large: 'row', medium: 'column', mobile: 'column'}),
            marginTop: '5vh',
            marginBottom: '3vh',
        }} >
            <div style={{
                display: 'flex',
                width: '45%',
                marginLeft: '5%',
                flexDirection: width < breakingPoint ? 'column' : 'row',
                justifyContent: 'space-between',

            }} >
                <Links 
                    header={'Skägg'}
                    links={[{
                        name: 'Handla',
                        link: '/get-started/step-1',
                    },{
                        name: 'Om oss',
                        link: '/about-us',
                    }]} 
                />
                <Links 
                    header={'Hjälp'}
                    links={[{
                        name: 'Kontakta oss',
                        link: '/contact',
                    },{
                        name: 'Våra villkor',
                        link: '/privacy-policy',
                    }
                    /* ,{
                        name: 'Integritetspolicy',
                        link: 'skagg/link',
                    } */
                ]} 
                />
                {/* <Links 
                header={'Följ oss'}
                links={[{
                    name: 'Instagram',
                    link: 'skagg/link'
                },
                {
                    name: 'Facebook',
                    link: 'skagg/link'
                },
                {
                    name: 'LinkedIn',
                    link: 'skagg/link'
                }]} /> */}
            </div>
            <div style={{
                width: DynamicSize({large: '30%', medium: '90%', mobile: '90%'}),

            }} >
                <p style={{
                    color: 'white'
                }} >
                    Registrera för nyhetsbladet
                </p>
                <div style={{
                    display: 'flex',
                    }} >
                    <div style={{
                        display: 'flex',
                        backgroundColor: 'grey',
                        width: '80%',
                        }} >
                        <input 
                            style={{
                                outline: 'none',
                                borderColor: 'transparent',
                                fontSize: 15,
                                backgroundColor: 'grey',
                                width: '100%'
                            }}
                            type={'text'}
                            placeholder={'Ange din mailadress'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' ? Subscribe() : null} />
                    </div>
                    <div style={{
                        backgroundColor: 'white',
                        paddingLeft: 10,
                        paddingRight: 10,
                        cursor: 'pointer'
                        }}
                        onClick={() => Subscribe()} >
                        <h3 style={{
                            fontSize: 12,
                            margin: 10
                        }} >
                            Skicka
                        </h3>
                    </div>    
                </div>





                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '15%',
                }} >

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }} >
                        <h3 style={{
                            color: 'white'
                        }} >
                            Socialt
                        </h3>
                        <div style={{
                            height: 30
                        }} >
                            <AiFillFacebook 
                                color='white' 
                                size={30} 
                                style={{
                                    marginRight: 10,
                                    cursor: 'pointer'
                                }}
                                onClick={() => window.open('https://www.facebook.com/profile.php?id=100093317635325')}  />
                            <AiFillInstagram 
                                color='white' 
                                size={30}
                                style={{
                                    marginRight: 10,
                                    cursor: 'pointer'
                                }}
                                onClick={() =>  window.open('https://www.instagram.com/skaggrazors')}
                                />
                            <AiFillLinkedin 
                                color='white' 
                                size={30}
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => window.open('https://www.linkedin.com/company/skaggrazors')} />
                        </div>
                        <div style={{
                            display: 'flex'
                        }} >
                            <img
                                src={metal_recycles_forever_logo}
                                alt='metal recycles forever'
                                style={{
                                    height: 40,
                                    marginTop: 30
                                }} />

                        </div>
                    </div>
                    <div>

                    <h3 style={{
                            color: 'white'
                        }} >
                            Säker betalning
                        </h3>
                   
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }} >
                        
                        <div style={{
                            marginBottom: 30,
                            display: 'flex',
                            alignItems: 'center'
                        }} >
                            <img 
                                src={visa_logo} 
                                alt="visa payment"
                                style={{
                                    height: 20,
                                    marginRight: 10,
                                    
                                }} />
                            <img 
                                src={mastercard_logo} 
                                alt="mastercard payment"
                                style={{
                                    height: 30
                                }} />
                        </div>
                        <div>
                        <h3 style={{
                            color: 'white'
                        }} >
                            Leveranspartner
                        </h3>
                            <img 
                                src={postnord_logo} 
                                alt="postnord"
                                style={{
                                    height: 20,
                                    
                                }} />
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>


        <div style={{
          //width: '33%'
          marginBottom: 20
        }} >
          {/* <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} >
            <HiOutlineMail color='white' size="40px" />
            <p style={{
              color: 'white',
              marginLeft: 10
            }} >
              kontakt@mysvall.se
            </p>
          </div> */}
        <h3 style={{
          color: 'white',
          fontSize: 10
          }} >
            Copyright © 2023 Svall Development AB</h3>
        </div>
     
      </footer>
  );
}

export default Footer;