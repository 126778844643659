import {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
//import logo from '../assets/SVALL_LOGO.svg';

//import logo from './logo.svg';
import { API } from "aws-amplify";
import * as queries from '../../graphql/queries';

import { useNavigate } from 'react-router-dom';

import NavigatorBar from '../navigator_bar';
import ProductBox from '../product_box';
import Footer from '../footer';
import DynamicSize from '../functions/dynamic_size';

function Subscription() {
  const navigate = useNavigate()
  const [products, setProducts] = useState()
  const [additionalProducts, setAdditionalProducts] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [cookie, setCookie, removeCookie] = useCookies(['shoppingBag', 'product'])

  async function GetProducts() {
    const fetchProducts = await API.graphql({
      query: queries.listProducts, variables: {filter: {CATEGORY: {eq: 'SUBSCRIPTION'}}}
    });
    const sortedProducts = fetchProducts.data.listProducts.items.sort((a, b) => a.productNumber - b.productNumber)
    setProducts(sortedProducts)
    setAdditionalProducts(sortedProducts)
    setSelectedProduct(sortedProducts[0].id)
  }

  useEffect(() => {
    GetProducts()
  }, [])

  function Click() {
    if (!selectedProduct) {
      return;
    }
    let changeOrder = false
    if (cookie.product) {
      cookie.product.map(p => {
        if (JSON.parse(p).product === selectedProduct) {
          changeOrder = true
          return;
        }
      })
    }
    if (!changeOrder) {
      const product = {
        product: selectedProduct,
        frequency: 0
      }
      setCookie('product', cookie.product ? [...cookie.product, JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'})
    }
    navigate('/get-started/step-2')
  }

  return (
    <div 
    //className='App'
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw'
    }}
    >
      <NavigatorBar />
      {!products ? null :
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f0efeb',
          //justifyContent: 'center',
          marginBottom: '5vh',
          paddingBottom: '5vh'
          //width: '100vw'
        }} >
           <h3 style={{
                    color: 'black',
                    fontSize: '70px',
                    fontFamily: 'Prompt'
                  }} >
                    Tilläggsprodukter
                  </h3>
          <h3 style={{
                    color: 'black',
                    fontSize: '30px',
                    marginBottom: '5vh',
                    fontFamily: 'Prompt',
                    fontWeight: '200'
                  }} >
                    Rakbladsprenumeration
                  </h3>
          <div 
          className="scrollbar"
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            width: '100vw',
            maxWidth: '100%',
            scrollbarWidth: 0,
            marginBottom: '5vh',
          }} >
            {additionalProducts.map((product, index) => (
                <ProductBox 
                  key={product.id}
                  id={product.id}
                  price={product.PRICE}
                  name={product.NAME}
                  s3file={product.S3FILE}
                  selectedProduct={selectedProduct} 
                  setSelectedProduct={setSelectedProduct}
                  index={index}
                  length={additionalProducts.length} />
            )
            )}
          </div>
          <div style={{
            backgroundColor: '#257062',
            height: '60px',
            width: '300px',
            maxWidth: '90vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: `1px 2px 5px #000`,
            cursor: 'pointer'
          }}
            onClick={() => Click()} >
            <h3 style={{
              textTransform: 'uppercase',
              fontFamily: 'Prompt',
              color: 'white'
            }} >
              Nästa steg
            </h3>
          </div>

        </div>
      }

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }} >
       
        <div style={{
            width: DynamicSize({large: '70vw', medium: '80vw', mobile: '90vw'}),
            maxWidth: 2000
          }} >
         
        </div>
        
          
      </div>

      <Footer />
    </div>
  );
}

export default Subscription;