import {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
//import logo from '../assets/SVALL_LOGO.svg';

//import logo from './logo.svg';
import { API } from "aws-amplify";
import * as queries from '../../graphql/queries';

import { useNavigate } from 'react-router-dom';

import NavigatorBar from '../navigator_bar';
import ProductBox from '../product_box';
import Footer from '../footer';

function ReferAFriend() {
  const navigate = useNavigate()
  const [products, setProducts] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [cookie, setCookie, removeCookie] = useCookies(['shoppingBag', 'product'])

  async function GetProducts() {
    const fetchProducts = await API.graphql({
      query: queries.listProducts, variables: {filter: {CATEGORY: {eq: "START_KIT"}}}
    });
    setProducts(fetchProducts.data.listProducts.items)

  }

  useEffect(() => {
    //GetProducts()
  }, [])

  function click() {
    let changeOrder = false
    if (cookie.product) {
      cookie.product.map(p => {
        if (JSON.parse(p).product === selectedProduct) {
          changeOrder = true
          return;
        }
      })
    }
    if (!changeOrder) {
      const product = {
        product: selectedProduct,
        frequency: 0
      }
      setCookie('product', cookie.product ? [...cookie.product, JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'})
    }
    navigate('/skagg/frequency')
  }

  

  return (
    <div 
    //className='App'
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw'
    }}
    >
      <NavigatorBar />
      


      <Footer />
    </div>
  );
}



export default ReferAFriend;