/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      PRICE
      orders {
        items {
          id
          productId
          orderId
          product {
            id
            PRICE
            NAME
            DESCRIPTION
            CATEGORY
            S3FILE
            S3FILE_EXTERNAL
            productNumber
            createdAt
            updatedAt
          }
          order {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          authOwner
        }
        nextToken
      }
      NAME
      DESCRIPTION
      CATEGORY
      S3FILE
      S3FILE_EXTERNAL
      productNumber
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        PRICE
        orders {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        NAME
        DESCRIPTION
        CATEGORY
        S3FILE
        S3FILE_EXTERNAL
        productNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      EMAIL
      FIRST_NAME
      STREET_ADDRESS
      POST_NUMBER
      COUNTRY
      Orders {
        items {
          id
          AMOUNT
          CURRENCY
          customerID
          Products {
            nextToken
          }
          ORDER_DATE
          PAYMENT_PROVIDER
          STATUS
          PREVIOUS_ORDER_ID
          ORDER_ID
          authOwner
          RECURRING
          DISCOUNT_CODE
          createdAt
          updatedAt
        }
        nextToken
      }
      PHONE_NUMBER
      APARTMENT_NUMBER
      CITY
      LAST_NAME
      EMAIL_OFFERS
      authOwner
      cognitoUserName
      REVOLUT_CUSTOMER_ID
      PAYMENT_PROVIDER
      REVOLUT_PAYMENT_METHOD_ID
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        EMAIL
        FIRST_NAME
        STREET_ADDRESS
        POST_NUMBER
        COUNTRY
        Orders {
          items {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          nextToken
        }
        PHONE_NUMBER
        APARTMENT_NUMBER
        CITY
        LAST_NAME
        EMAIL_OFFERS
        authOwner
        cognitoUserName
        REVOLUT_CUSTOMER_ID
        PAYMENT_PROVIDER
        REVOLUT_PAYMENT_METHOD_ID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customersByEMAIL = /* GraphQL */ `
  query CustomersByEMAIL(
    $EMAIL: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByEMAIL(
      EMAIL: $EMAIL
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EMAIL
        FIRST_NAME
        STREET_ADDRESS
        POST_NUMBER
        COUNTRY
        Orders {
          items {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          nextToken
        }
        PHONE_NUMBER
        APARTMENT_NUMBER
        CITY
        LAST_NAME
        EMAIL_OFFERS
        authOwner
        cognitoUserName
        REVOLUT_CUSTOMER_ID
        PAYMENT_PROVIDER
        REVOLUT_PAYMENT_METHOD_ID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customersByREVOLUT_CUSTOMER_ID = /* GraphQL */ `
  query CustomersByREVOLUT_CUSTOMER_ID(
    $REVOLUT_CUSTOMER_ID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByREVOLUT_CUSTOMER_ID(
      REVOLUT_CUSTOMER_ID: $REVOLUT_CUSTOMER_ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EMAIL
        FIRST_NAME
        STREET_ADDRESS
        POST_NUMBER
        COUNTRY
        Orders {
          items {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          nextToken
        }
        PHONE_NUMBER
        APARTMENT_NUMBER
        CITY
        LAST_NAME
        EMAIL_OFFERS
        authOwner
        cognitoUserName
        REVOLUT_CUSTOMER_ID
        PAYMENT_PROVIDER
        REVOLUT_PAYMENT_METHOD_ID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      AMOUNT
      CURRENCY
      customerID
      Products {
        items {
          id
          productId
          orderId
          product {
            id
            PRICE
            NAME
            DESCRIPTION
            CATEGORY
            S3FILE
            S3FILE_EXTERNAL
            productNumber
            createdAt
            updatedAt
          }
          order {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          authOwner
        }
        nextToken
      }
      ORDER_DATE
      PAYMENT_PROVIDER
      STATUS
      PREVIOUS_ORDER_ID
      ORDER_ID
      authOwner
      RECURRING
      DISCOUNT_CODE
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AMOUNT
        CURRENCY
        customerID
        Products {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        ORDER_DATE
        PAYMENT_PROVIDER
        STATUS
        PREVIOUS_ORDER_ID
        ORDER_ID
        authOwner
        RECURRING
        DISCOUNT_CODE
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersByCustomerIDAndORDER_DATE = /* GraphQL */ `
  query OrdersByCustomerIDAndORDER_DATE(
    $customerID: ID!
    $ORDER_DATE: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByCustomerIDAndORDER_DATE(
      customerID: $customerID
      ORDER_DATE: $ORDER_DATE
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AMOUNT
        CURRENCY
        customerID
        Products {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        ORDER_DATE
        PAYMENT_PROVIDER
        STATUS
        PREVIOUS_ORDER_ID
        ORDER_ID
        authOwner
        RECURRING
        DISCOUNT_CODE
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersBySTATUSAndORDER_DATE = /* GraphQL */ `
  query OrdersBySTATUSAndORDER_DATE(
    $STATUS: String!
    $ORDER_DATE: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersBySTATUSAndORDER_DATE(
      STATUS: $STATUS
      ORDER_DATE: $ORDER_DATE
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AMOUNT
        CURRENCY
        customerID
        Products {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        ORDER_DATE
        PAYMENT_PROVIDER
        STATUS
        PREVIOUS_ORDER_ID
        ORDER_ID
        authOwner
        RECURRING
        DISCOUNT_CODE
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      id
      productId
      orderId
      product {
        id
        PRICE
        orders {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        NAME
        DESCRIPTION
        CATEGORY
        S3FILE
        S3FILE_EXTERNAL
        productNumber
        createdAt
        updatedAt
      }
      order {
        id
        AMOUNT
        CURRENCY
        customerID
        Products {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        ORDER_DATE
        PAYMENT_PROVIDER
        STATUS
        PREVIOUS_ORDER_ID
        ORDER_ID
        authOwner
        RECURRING
        DISCOUNT_CODE
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      authOwner
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        orderId
        product {
          id
          PRICE
          orders {
            nextToken
          }
          NAME
          DESCRIPTION
          CATEGORY
          S3FILE
          S3FILE_EXTERNAL
          productNumber
          createdAt
          updatedAt
        }
        order {
          id
          AMOUNT
          CURRENCY
          customerID
          Products {
            nextToken
          }
          ORDER_DATE
          PAYMENT_PROVIDER
          STATUS
          PREVIOUS_ORDER_ID
          ORDER_ID
          authOwner
          RECURRING
          DISCOUNT_CODE
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        authOwner
      }
      nextToken
    }
  }
`;
export const orderProductsByProductId = /* GraphQL */ `
  query OrderProductsByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderProductsByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        orderId
        product {
          id
          PRICE
          orders {
            nextToken
          }
          NAME
          DESCRIPTION
          CATEGORY
          S3FILE
          S3FILE_EXTERNAL
          productNumber
          createdAt
          updatedAt
        }
        order {
          id
          AMOUNT
          CURRENCY
          customerID
          Products {
            nextToken
          }
          ORDER_DATE
          PAYMENT_PROVIDER
          STATUS
          PREVIOUS_ORDER_ID
          ORDER_ID
          authOwner
          RECURRING
          DISCOUNT_CODE
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        authOwner
      }
      nextToken
    }
  }
`;
export const orderProductsByOrderId = /* GraphQL */ `
  query OrderProductsByOrderId(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderProductsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        orderId
        product {
          id
          PRICE
          orders {
            nextToken
          }
          NAME
          DESCRIPTION
          CATEGORY
          S3FILE
          S3FILE_EXTERNAL
          productNumber
          createdAt
          updatedAt
        }
        order {
          id
          AMOUNT
          CURRENCY
          customerID
          Products {
            nextToken
          }
          ORDER_DATE
          PAYMENT_PROVIDER
          STATUS
          PREVIOUS_ORDER_ID
          ORDER_ID
          authOwner
          RECURRING
          DISCOUNT_CODE
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        authOwner
      }
      nextToken
    }
  }
`;
