import NavigatorBar from '../navigator_bar';
import Footer from '../footer';

function PrivacyPolicy() {

    function Header({text}) {
        return(
            <p style={{
                fontWeight: 'bold'
            }} >
                {text}
            </p>
        )
    }

    function HeaderSection({text}) {
        return(
            <p style={{
                fontWeight: 'bold'
            }} >
                {text}
            </p>
        )
    }

    function List({text}) {
        return(
            <p>
                {text}
            </p>
        )
    }

    function Section({text}) {
        return(
            <p style={{

            }} >
                {text}
            </p>
        )
    }



  return (
    <div 
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw'
        }}
        >
        <NavigatorBar />

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '80vw',
            alignItems: 'flex-start',
            marginLeft: '10vw'
        }} >
            <p style={{
                fontWeight: 'bold',
                fontSize: 30,
                alignSelf: 'center'
            }} >
                Privacy Policy
            </p>
            <Section text={"We at Skägg, (\"Skägg,\" \"our,\" \"we,\" or \"us\") value our users' privacy as we know that you care about how your personal information is collected, used, safeguarded, shared, and we take that seriously. Kindly read the following terms to learn more about your privacy as this Privacy Policy (\"Policy\") will help you understand how we collect and use personal information from those who use access and make use of our Site and the services that we offer."} />
            <Section text={"This privacy policy also helps you understand the rights and choices that you have with respect to your personal information."} />
            <Section text={"By accessing or using the Service in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways."} />

            <Header text={"1. Changes to this Privacy Policy"} />
            <Section text={"We reserve the right to make changes to this Policy at any given time. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page. If at any point in time Skägg decides to make use of any personally identifiable information on file, in a manner vastly different from that which was stated when this information was initially collected, the user or users shall be promptly notified by email. Users at that time shall have the option as to whether to permit the use of their information in this separate manner."} />

            <Header text={"2. What does this privacy policy cover?"} />
            <Section text={"This Policy applies to Skägg, and it governs any and all data collection and usage by us in compliance with applicable regulations. Through the use of these Services, you are consenting to the data collection procedures expressed in this Policy."} />
            <Section text={"We gather various types of information from our users, as explained in more detail below, and we use this information internally in connection with our Services, including to personalize, provide, and improve our Services, to allow you to make payments, to communicate with you, and to analyze how you use the Services. In certain cases, we may also share some information with third parties, but only as described below."} />
            <Section text={"Please note that this Policy does not govern the collection and use of information by companies that Skägg does not control, nor by individuals not employed or managed by us. If you visit a website that is made available via the service or link, be sure to review its privacy policy before providing the site with information. It is highly recommended and suggested that you review the privacy policies and statements of any website you choose to use or frequent to better understand how such websites garner, make use of and share the information collected."} />

            <Header text={"3. Information we collect"} />
            <Section text={"When you make use of our Services, we may collect and process a variety of personal information about you and from various sources which may include but are not limited to the following as described below:"} />
            <HeaderSection text={"A. Information you provide: "} />
            <Section text={"\"Account profile and Personal Information\" means any information which, either alone or in combination with other information we hold about you, identifies you as an individual or as a business, including, for example, your contact information such as your name, email address, telephone, location information, demographic information, as well as any other non-public information about you that is associated with or linked to any of the foregoing data which you may be required to provide when using the Service. You have the choice to add your photo, profession, and any other details to your public-facing profile. When you create your profile, others will be able to view some details about your activity on the Skägg Services, including the content you post. We collect this information when you sign-up for the Service, engage our interactive services, feedback and survey responses, request customer support or otherwise communicate with us."} />
            <Section text={"\"Anonymous/Aggregated Data\" means data that is not associated with or linked to your Personal Information; Anonymous Data does not, by itself, permit the identification of individual persons."} />
            <Section text={"We collect Personal Information, Anonymous Data and a few other categories of information, from a few different sources, as described below:"} />
            <List text={"i) Interests and usage, including information about your interests (if you add any) and how you use the Service, such as searches you make, events you are interested in, the feedback you give and or that are given about exchanges and User Generated Content, actions you take and the time and frequency of use;"} />
            <List text={"ii) When you post User Generated Content or provide us with feedback on our Service or on our community, the information contained in such user content will be stored on our servers and will be available to be seen by other users who make use of our Service."} />
            <List text={"iii) We also collect other types of Personal Data that you provide to us voluntarily, such as your operating system and version and other requested information if you contact us via email regarding support for the Service."} />
            <Section text={"Skägg does not collect any Special Category of information about you such as your race, ethnicity, philosophical or religious beliefs, sexual orientation, political opinions, information about your health and genetic and biometric data)."} />
            <Section text={"Please note that you can choose not to provide us with certain information, but this may limit the features of the Services you can use."} />
            
            <HeaderSection text={"B. Information we collect automatically"} />
            <Section text={"We may collect certain information (which does not identify you as an individual) about your use of the Service through the use of tracking technologies or by other passive means and other relevant statistics, including the following:"} />
            <List text={"i) Usage Data: When you access and/or make use of our Services, we collect details of your visit such as traffic data, date and time of your visit to the Service, logs, location, error tracking information, and other usage data and resources that you access and use on or in the Services."} />
            <List text={"ii) Device and location information: We may also collect information about your mobile, internet connection, and computer device, such as your operating system, IP address, and browser type."} />
            <List text={"iii) Cookies: When you access and/or make use of our Services, we may send one or more cookies (small text files containing a string of characters) to your device that uniquely identifies your browser. We use cookies to improve the quality of the software and service by storing user preferences and tracking user trends. You can refuse to accept browser cookies on your computer by activating the relevant browser setting, and you may have the same capabilities on your mobile device under the choices for your operating system or browser. If you choose this option, you may be unable to access or utilize certain features of our Services. Unless you have configured your browser or operating system to reject cookies, our system will send cookies when you visit our Service."} />
            <List text={"iv) Links: The Service or contents made available on the Service may include links in a format that enables us to keep track of whether these links have been followed by IP addresses. We use this information to improve the quality of our services and design."} />
            <List text={"v) Beacons: Beacons (also known as \"pixel tags\" or \"clear GIFs\") are 1×1 single-pixel graphics that allow us to count the number of users who have visited or accessed the Service and to recognize users by accessing our cookies. We may employ beacons to facilitate Service administration and navigation, track the actions of users of the Service, compile aggregated statistics about Service usage and response rates, and provide an enhanced online experience for visitors to the Services."} />
            <List text={"vi) Analytics services: We may use third-party analytics services (\"Analytics Services\"), such as Google Analytics, to help monitor, visualize, report, integrate and analyze how users use our Services. Google Analytics also make use of cookies, which are text files placed on your computer, to help our Services analyze how users use the Services. Furthermore, the information generated through cookies or other technologies about your use of the Skägg Service (the \"Analytics Information\") may be transmitted to the Analytics Services. The Analytics Services use Analytics Information to compile reports on user activity. The Analytics Services may also transfer the Analytics Information to third parties where required to do so by law, or where such third parties process Analytics Information on their behalf. Each Analytics Service's ability to use and share Analytics Information is restricted by such Analytics Service's terms of use and privacy policy."} />
            <List text={"vii) Payment transaction information: Through the use of a third-party payment processor, certain financial information used by you for payment transactions on our Services may be collected. Payment amount, date and time of the transaction, payment instrument expiration date and billing postcode, user's address and other details related to the transaction may be collected as well."} />

            <HeaderSection text={"C. Information automatically collected by third parties about you"} />
            <Section text={"Some embedded content on the Service may be served by third-party service providers. Please note that these companies may also use cookies or other similar technologies to collect data about you, both on the Skägg Services and throughout the Internet. Data collection and use by those third parties is subject to the privacy policies of those third-parties "} />
            <Section text={"Please rest assured that this Service will only collect personal information that you knowingly and willingly provide to us by way of surveys, completed forms, and emails. Skägg intends to use personal information only for the purpose for which it was requested, and for any additional uses specifically provided for on this Policy."} />

            <Header text={"4. What we use your personal information for"} />
            <Section text={"Skägg uses the information collected from you to provide, improve, and develop the services we provide to you, serve you adverts that we think might be of interest to you, communicate with you, offer you a better service, and protect us and our users. When you interact with or make use of the Skägg service, we use a variety of technologies to collect information about you for various reasons."} />
            <List text={"i) To create and service your user account on the service and to enable certain functionalities for your use on our Services."} />
            <List text={"ii) To validate your identity and identify you as a user of the Services and ensure the security of your account -e.g., by sending an email to confirm that you are the owner of the email address linked to your user account."} />
            <List text={"iii) To facilitate the processing of your payments on our platforms and to communicate with you in connection with our services or any changes in our Terms and Conditions, Privacy Policy or other policies that apply to your use of the Skägg Services."} />
            <List text={"iv) To personalize the content and information that we may send or display to you, to offer location customization (where permitted by applicable law), connect with other users, personalized help and instructions, and otherwise personalize your experiences while using our website and/or our services, such as developing and offering you with new features to the services we are providing you."} />
            <List text={"v) Conducting research and analysis to help develop relevant and helpful features."} />
            <List text={"vi) Advertising: We use the information you make available to us to serve you with interest-based advertising when you make use of the Skägg Services, to make improvements to our advertising and measurement mechanisms so we can serve ads that are more relevant to you and your interest and to as well, analyze the effectiveness and reach of our ads and ads services. Offering adverts is in our legitimate commercial interests. Where required, we'll get your permission before starting this processing."} />
            <List text={"vii) For communication purposes: We may send emails, product updates and related emails to the email address you provide to us and text messages to any cellphone number you provided to us, and for informational and operational purposes, such as account management, customer service, system maintenance, and other Service-related purposes. We use personal information that we gather from you to communicate with you in relation to your use of the Skägg Service via different channels (e.g., by email, chat, phone)."} />
            <List text={"viii) Fraud detection and prevention: Increase and maintain the safety and security of our products and services, community and prevent misuse. We use personal information to detect and prevent fraud and abuse in order to protect us and ensure the safety of our users, Skägg, and others. We also use collected information to protect against, identify theft and prevent fraud and other criminal activity, claims and other liabilities"} />
            <List text={"ix) Provide, troubleshoot, and improve Skägg Services: We use your personal information to understand, diagnose, troubleshoot, and fix issues with the Skägg Services. We also use such information to operate, evaluate, develop, manage and improve our Services (including operating, administering, analyzing and improving our products and services; developing new features and services; managing and evaluating the effectiveness of our communications; performing accounting, auditing, billing reconciliation and collection activities and other internal functions;"} />
            <List text={"x) Comply with and enforce applicable legal requirements: in certain cases, we collect your personal information to stay in compliance with laws and relevant industry standards and policies, including this Privacy Policy and our Terms and Conditions of use."} />

            <Header text={"5. Data retention and deletion"} />
            <Section text={"We keep your personal information only as long as necessary to provide you with the Skägg Service and for legitimate and essential business purposes, such as maintaining the performance of the Skägg Service, making data-driven business decisions about new features and offerings, complying with our legal obligations, and resolving disputes. We keep some of your personal information for as long as you are a user of the Skägg Service."} />
            <Section text={"If you request, we will delete or anonymize your personal information so that it no longer identifies you, unless we are legally allowed or required to maintain certain personal information, including situations such as the following:"} />
            <List text={"i) If there is an unresolved issue relating to your use of the service, such as an unresolved claim or dispute, we will retain the necessary personal information until the issue is resolved;"} />
            <List text={"ii) Where we need to retain the personal information for our legal, tax, audit, and accounting obligations, we will retain the necessary personal information for the period required by applicable law; and/or,"} />
            <List text={"iii) Where necessary for our legitimate business interests such as fraud prevention or to maintain the security of our users and the platform."} />

            <Header text={"6. Your privacy rights"} />
            <Section text={"General Data Protection Regulations grant you certain rights ('information rights') which provide you with:"} />
            <List text={"i) Right of rectification or erasure - if you feel that any data that we hold about you is inaccurate you have the right to ask us to correct or rectify it. You also have a right to ask us to erase information about you where you can demonstrate that the data we hold is no longer needed by us if you withdraw the consent upon which our processing is based, or if you feel that we are unlawfully processing your data. Your right of rectification and erasure extends to anyone we have disclosed your personal information to and we will/shall take all reasonable steps to inform those with whom we have shared your data about your request for erasure. Note that we may have a legal obligation to retain some or even all personal information about you under relevant laws. We may also have the legal right to retain the information you have requested to be erased. "} />
            <List text={"ii) Right to restriction of processing - you have a right to request that we refrain from processing your data where you contest its accuracy, or the processing is unlawful and you have opposed its erasure, or where we don't need to hold your data anymore but you need us to in order to establish, exercise or defend any legal claims, or we are in dispute about the legality of our processing your personal information."} />
            <List text={"iii) Right to data portability: In certain circumstances, you may have the right to request that data which you have provided to us be made available to you so that you can transfer this to another data controller."} />
            <List text={"iv) Right to object - you have a right to object to our processing of your personal information where the basis of the processing is our legitimate interests including but not limited to direct marketing and profiling."} />
            <List text={"v) Right to withdraw consent - you have the right to withdraw your consent for the processing of your personal information where the processing is based on consent. To withdraw consent, please specify what you wish to withdraw consent for, by emailing us at kontakt@skaggrazors.com. "} />
            <List text={"vi) Right of complaint - if you are unhappy with the way we have used or are handling your personal information you have the right to lodge a complaint with a supervisory authority for data protection issues. We would, however, appreciate the chance to deal with your concerns before you approach such a Supervisory Authority. So, please contact us in the first instance by emailing us at kontakt@skaggrazors.com "} />
            
            <Header text={"7. Keeping your data safe"} /> 
            <Section text={"As a matter of policy, we are committed to protecting your data and as such, Skägg takes precautions to protect your information. We adopt commercially reasonable measures to secure Personal Information by using physical and electronic measures designed to ensure the integrity and security of the Personal Information we hold. When you submit sensitive information via the Service, your information is protected. Any payment transactions will be encrypted using SSL technology. Where you have chosen a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone."} />
            <Section text={"However, please note that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Although we will do our best to protect the personal information that we gather about you, the transmission of information via the internet is not completely secure and as such, we cannot guarantee the absolute security of your data transmitted to the Service; any transmission is at your own risk. We have implemented various policies including encryption, access, and retention policies to guard against unauthorized access and unnecessary retention of personal information in our systems."} />

            <Header text={"8. Children under the age of 18/Age restriction"} />
            <Section text={"Skägg's Services are not directed to and do not knowingly collect personally identifiable information from children under the age of thirteen (13). If it is determined that such information has been inadvertently collected on anyone under the age of thirteen (13), we shall immediately take the necessary steps to ensure that such information is deleted from our system's database. "} />

            <Header text={"9. Your choices; unsubscribe or opt-out"} />
            <Section text={"Account information: You may update certain information you provide to us (such as your password, name and email address) by logging into your Skägg account or contacting us through our support channel kontakt@skaggrazors.com. Also, if you decide to delete your Skägg account, please contact us at kontakt@skaggrazors.com but note that we may retain certain information where required or permitted by law. We may also retain cached or archived copies of certain information about you and your usage of the Services for a certain period of time."} />
            <Section text={"Advertising Preferences: We try to serve you ads that we think will be relevant to your interests. If you would like to modify the information we and our advertising partners use to select these ads, you can do so through your preferences settings on the website."} />
            <Section text={"Do not track: Some browsers have\"Do Not Trac\" features that let you send a request to sites to not track your online activities. We, like many other sites, do not honor those requests."} />

            <Header text={"10. Links to other sites or resources"} />
            <Section text={"Skägg does not claim nor accept responsibility for any privacy policies, practices and/or procedures of other third-party sites or resources. Therefore, we encourage all users and visitors to be aware when they leave our Service and to read the privacy statements of websites that collects personally identifiable information. This Privacy Policy Agreement applies only and solely to the information collected by us. "} />

   




        </div>


        <Footer />
    </div>
  );
}



export default PrivacyPolicy;