import {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
import { API, Storage } from "aws-amplify";
import * as queries from '../../graphql/queries'
import moment from 'moment';
import SimpleNavigatorBar from '../simple_navigator_bar';
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { HiOutlineMail, HiScale } from 'react-icons/hi';
import { FaTrash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import '../order.css';
import RevolutCheckout from "@revolut/checkout";
import DynamicSize from '../functions/dynamic_size';

function InputField({text, value, setValue, columns, type, prefix, validInput, disabled, autocomplete}) {
    //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
    return(
        <div style={{
            borderStyle: 'solid',
            borderColor: validInput ? 'red' : 'black',
            borderRadius: '10px',
            width: columns > 1 ? '48%' : '100%',
            height: '50px',
            display: 'flex',
            paddingTop: '5px',
            paddingBottom: '5px',
            marginBottom: '20px'
        }} >
             {prefix ? 
                 <PhoneInput
                 style={{
                     width: '90%',
                     fontSize: 20,
                     marginLeft: 10,
                     borderColor: 'transparent',
                     outline: 'none'
                 }}
                 defaultCountry="SE"
                 placeholder="Telefonnummer"
                 value={value}
                 onChange={setValue}/>
            : 
            <input style={{
                width: prefix ?  '80%' : '95%',
                borderColor: 'transparent',
                outline: 'none',
                paddingLeft: '10px',
                fontSize: '20px',
                }} 
                type={type}
                placeholder={text}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={disabled ? true : false}
                autoComplete={autocomplete}
                />}
            
        </div>
    )
}

function Credentials({nextDelivery, productIDs, futureProductIDs, futureTotalPrice, loadingPayment, setLoadingPayment, totalPrice}) {
    const navigate = useNavigate()
    const width = useWindowWidth()
    const breakingPoint = 1200 //change to dynamic size
    const mobileBreakingPoint = 500
}

function SignUpCustomer() {
    const navigate = useNavigate()
    //const [selectedProduct, setSelectedProduct] = useState([])
    const [products, setProducts] = useState()
    const [productIDs, setProductIDs] = useState([])
    const [futureProductIDs, setFutureProductIDs] = useState()
    const [totalPrice, setTotalPrice] = useState(0)
    const [futureTotalPrice, setFutureTotalPrice] = useState()
    const [nextDelivery, setNextDelivery] = useState()
    const [loadingPayment, setLoadingPayment] = useState(false)
    const [swishQrCode, setSwishQrCode] = useState()
    
    const width = useWindowWidth()
    const breakingPoint = 1200 //change to dynamic size
    const mobileBreakingPoint = 500

    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [notValidPhoneNumber, setNotValidPhoneNumber] = useState(false)


    async function CreateCustomer({email, provider, phoneNumber}) {
        try {
            const adjustedPhoneNumber = phoneNumber.substring(1)
            const url = 'https://6wazzgtdug3sjhboeqka3xt56i0pkixh.lambda-url.eu-north-1.on.aws/' //svallwebcreatecustomer
            const response = await fetch(
                url
                +'?email='+email
                +'&provider='+provider
                +'&country=Sverige'
                +'&phoneNumber='+adjustedPhoneNumber
            );
            const json = await response.json()
            if (json.approved) {
                return(json.response)
            }
            else if (json.response.includes('PHONE_NUMBER')) {
                setNotValidPhoneNumber(true)
                return(null)
            }
            else {
                return(null)
            }
        } catch (error) {
            console.log('error in CreateCustomer', error)
        }
    }

    async function PayWithSwish({amount, email, phoneNumber}) {
        try {
            setLoadingPayment(true)
            const customer_id = await CreateCustomer({
                email: email, 
                provider: 'SWISH',
                phoneNumber: phoneNumber
            })
            console.log('customer', customer_id)
            if (!customer_id) {
                setLoadingPayment(false)
                return;
            }
            const orderDate = JSON.stringify(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')).replace('+', '%2b')
            const nextOrderDate = JSON.stringify(moment().add(Math.round(nextDelivery/6*2), 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')).replace('+', '%2b')
            const url = 'https://6mkg5e2mvfx3pcqrxx6ps5xkia0kepce.lambda-url.eu-north-1.on.aws/' //svallwebswishpayment
            const response = await fetch(
                url
                    +'?amount='+amount
                    +'&currency=SEK'
                    +'&customer_id='+customer_id
                    +'&products='+JSON.stringify(productIDs)
                    +'&future_products='+JSON.stringify(futureProductIDs)
                    +'&order_date='+orderDate
                    +'&frequency='+nextOrderDate
                    +'&future_price='+futureTotalPrice
                    +'&recurring='+nextDelivery
            )
            const res = await response.json()


            const url2 = 'https://m3l3yg3gzi2dqjdjb4ayc3yvdq0odghh.lambda-url.eu-north-1.on.aws/' //prod
            const response2 = await fetch(
                url2
                    +'?amount='+amount
                    +'&payeePaymentReference='+res.order_id
            )
            const res2 = await response2.json()
            setSwishQrCode("data:image/png;base64," + res2.img)
            setLoadingPayment(false)
        } catch (error) {
            console.log('error in PayWithSwish', error)
        }
    }
    
    async function Pay({amount, currency}) {
        try {
            console.log('pay')
            
            return;
            setLoadingPayment(true)
            const customer_id = await CreateCustomer({
                email: email,
                provider: "REVOLUT" 
            })
            if (!customer_id) {
                setLoadingPayment(false)
                return;
            }
            const orderDate = JSON.stringify(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')).replace('+', '%2b')
            const nextOrderDate = JSON.stringify(moment().add(Math.round(nextDelivery/6*2), 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')).replace('+', '%2b')
            //const url = 'https://5jgjkmtztmroj7gziiq4k32uc40llzeo.lambda-url.eu-north-1.on.aws/' //staging
            const url = 'https://znepylxyprl75fg4yhkmmxgkea0iwlrz.lambda-url.eu-north-1.on.aws/' //prod
            console.log('url')
            const response = await fetch(
                url
                    +'?amount='+amount
                    +'&currency='+currency
                    +'&customer_id='+customer_id
                    +'&products='+JSON.stringify(productIDs)
                    +'&future_products='+JSON.stringify(futureProductIDs)
                    +'&order_date='+orderDate
                    +'&frequency='+nextOrderDate
                    +'&future_price='+futureTotalPrice
                    +'&recurring='+nextDelivery
            );
            const json = await response.json();
            return(
                <script>
                    {RevolutCheckout(json.public_id, "sandbox").then(function (instance) {
                        setLoadingPayment(false)
                        instance.payWithPopup({
                            locale: "sv",
                            email: email,
                            savePaymentMethodFor: 'merchant',
                            onSuccess() {
                                //window.alert("Thank you!");
                            },
                            onError(message) {
                                window.alert(message);
                            },
                        });
                    })}
            </script>)
        } catch (error) {
        }
    }
    
    async function GetProducts() {
        const fetchProducts = await API.graphql({
          query: queries.listProducts
        });
        setProducts(fetchProducts.data.listProducts.items.filter((product) => product.CATEGORY === 'START_KIT').sort((a, b) => a.productNumber - b.productNumber))
        setFutureProductIDs([fetchProducts.data.listProducts.items.filter((product) => product.CATEGORY === 'SUBSCRIPTION')[0].id])
        setFutureTotalPrice(fetchProducts.data.listProducts.items.filter((product) => product.CATEGORY === 'SUBSCRIPTION')[0].PRICE)
      }
    
      useEffect(() => {
        GetProducts()
      }, [])

      function Click({productID, number, numberOfProducts, price}) {
        if (numberOfProducts > number) {
            const removePrice = price * numberOfProducts
            setProductIDs(productIDs.filter(s => s !== productID))
            setTotalPrice(previousState => (previousState - removePrice))
            numberOfProducts = 0
        }
        for (let counter = numberOfProducts; counter < number; counter++) {
            setProductIDs(previousState => ([...previousState, productID]))
            setTotalPrice(previousState => (previousState + price))
        }
      }

      function Numbers({productID, price}) {
        const numberArray = [0, 1, 2, 3]
        let numberOfProducts = 0
        productIDs.map(p => (
            p === productID ? numberOfProducts = numberOfProducts + 1 : null)
        )
        return(
            <div style={{
                display: 'flex',
            }} >
                {numberArray.map(number => 
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 30,
                        height: 30,
                        width: 30,
                        borderStyle: number > 0 ? 'solid' : 'none',
                        borderWidth: 1,
                        borderRadius: 5,
                        borderColor: numberOfProducts == number && number > 0 ? 'blue' : 'black'
                    }}
                    onClick={() =>  Click({productID: productID, number: number, numberOfProducts, price: price})}
                    key={number}
                    >
                    {number < 1 ? 
                        <FaTrash />
                        :
                        <h3>
                            {number}
                        </h3>
                        }
                    </div>
                    
                    )}
            </div>
        )
    }

      if (!products) {
        return(null)
      }


    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
          }}>
            {loadingPayment ? 
            <div style={{
                display: 'flex',
                position: 'fixed',
                justifyContent: 'center',
                backgroundColor: 'grey',
                opacity: 0.5,
                width: '100%',
                height: '100%',
                
            }} >
            <Spinner style={{
                top: '35vh',
            }} 
            size={20} />

            </div>
        : null}

        <SimpleNavigatorBar />
        
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginTop: '10vh',
            marginBottom: '10vh'
        }} >
             <div style={{
            width: width < breakingPoint ? width < mobileBreakingPoint ? '95vw' : '70vw' : '40vw',
            maxWidth: 1000
        }} >
            <h3>
                Kontaktinformation
            </h3>
            {InputField({
                text: 'Mail',
                value: email,
                setValue: setEmail,
                columns: 1,
                type: 'email',
                autocomplete: 'email'
                })}
            {InputField({
                text: 'Telefonnummer',
                value: phoneNumber,
                setValue: setPhoneNumber,
                columns: 1,
                type: 'text',
                prefix: true,
                validInput: notValidPhoneNumber,
                autocomplete: 'tel-national'
                })}
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }} >
            </div>
        </div>
            <div style={{
                width: width < breakingPoint ? width < mobileBreakingPoint ? '95vw' : '70vw' : '40vw',
                maxWidth: 1000
            }} >
                {products.map(product => 
                    <div
                        key={product.id}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 30
                        }} 
                    >
                        <h3>
                            {product.NAME}
                        </h3>
                        <Numbers 
                            productID={product.id}
                            price={product.PRICE}
                             />
                    </div>

                
                )}    
            </div> 
            <div style={{
                display: 'flex',
                width: width < breakingPoint ? width < mobileBreakingPoint ? '95vw' : '70vw' : '40vw',
                maxWidth: 1000,
                justifyContent: 'space-between',
                marginBottom: '5%'
            }} >
                <div style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderRadius: '10px',
                    width: '45%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                onClick={() => setNextDelivery(42)} >
                    <p>
                        6 veckor
                    </p>
                </div>
                <div style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderRadius: '10px',
                    width: '45%',
                    display: 'flex',
                    justifyContent: 'center'
                }} 
                onClick={() => setNextDelivery(63)}
                >
                    <p>
                        9 veckor
                    </p>
                </div>
            </div>
            <h3>
                {totalPrice}
            </h3>

            {totalPrice > 0 && nextDelivery ? 
                <div 
                    onClick={() => loadingPayment ? null : PayWithSwish({amount: totalPrice, email: email, phoneNumber: phoneNumber})}
                    style={{
                        backgroundColor: 'black',
                        width: width < breakingPoint ? width < mobileBreakingPoint ? '95vw' : '70vw' : '40vw',
                        maxWidth: 1000,
                        height: '60px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        boxShadow: `1px 2px 5px #000`,
                        margin: '5%'
                    }} >
                    <p style={{
                        color: 'white'
                    }} >
                        BETALA
                    </p>
                </div>
            : null } 
            {swishQrCode ? <img src={swishQrCode} /> : null}
        </div>
        </div>
    );
}
export default SignUpCustomer;