import DynamicSize from "./functions/dynamic_size"
import { GiSharpShuriken } from 'react-icons/gi'
import { BiMailSend } from 'react-icons/bi'
import { RiPlantLine } from 'react-icons/ri'

import {useEffect, useState} from 'react'
import { API } from "aws-amplify";
import * as queries from '../graphql/queries';


import ProductBox from './product_box';

//https://tinloof.com/blog/how-to-build-an-auto-play-slideshow-with-react
function SkaggSection2() {
  const delay = 4000

  const [products, setProducts] = useState()
  const [selectedProduct, setSelectedProduct] = useState()

  const allData = [{
    text: 'Alltid fri frakt och leverans till dörren',
  },{
    text: 'Produkter i naturmaterial',
  },{
    text: 'Minimalt slitage på planeten',
  }
  ]

  function Slideshow() {
    const [index, setIndex] = useState()

    useEffect(() => {
      setTimeout(() =>
          setIndex((prevIndex) => 
            typeof(prevIndex) === 'undefined' ? 0 :
              prevIndex === allData.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      );
      return () => {};
    }, [index]);
    return (
      <div 
          style={{
            flexDirection: 'column',
            overflow: 'hidden',
          }} >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              transform: `translate3d(0, ${-index * 100}%, 0)`,
              transition: 'ease 1000ms',
              height: 20
            }} >
              {allData.map((data, index) => (
                <div key={index} >
                  <div style={{
                      //width: '100vw',
                      //height: 20,
                      display: 'flex',
                      
                      justifyContent: 'center',
                      alignItems: 'center'
                      }} >
                    
                    <h3 style={{
                        textTransform: 'uppercase',
                        height: 20,
                        fontSize: 15,
                        fontFamily: 'Prompt'
                      }} >
                      {data.text}  
                    </h3>
                    

                  </div>

              </div>
            ))}
            </div>
          </div>
    );
  }


    return(
       <div style={{
           }} >
          <Slideshow/>
        </div>
    )
}


export default SkaggSection2;

