import {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Auth } from 'aws-amplify'
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import moment from 'moment';
import { jsPDF } from "jspdf";
import { Spinner } from "react-activity";
import DynamicSize from './functions/dynamic_size';

import { HiOutlineMail } from 'react-icons/hi';
import SimpleNavigatorBar from './simple_navigator_bar';

function Part1(user) {
    const navigate = useNavigate()
    async function signOut() {
        
        try {
          await Auth.signOut();
          navigate('/')
        } catch (error) {
          console.log('error signing out: ', error);
        }
    }
    return(
        <div style={{
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            //flexDirection: 'column',
            alignItems: 'center',
        }} >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '2%',
                paddingRight: '2%'
            }} >
                <h3 style={{
                    color: '#257062',
                    fontSize: DynamicSize({large: 60, medium: 40, mobile: 20})
                }} >
                    Välkommen
                </h3>
                <p>{user.user.attributes.email}</p>
            <div style={{
                //position: 'absolute',
                position: DynamicSize({large: 'absolute', medium: 'absolute', mobile: 'relative'}),
                right: DynamicSize({large: '5%', medium: '5%', mobile: null}),
                width: DynamicSize({large: '10%', medium: '10%', mobile: '50%'})
            }} >
                <div 
                    style={{
                        backgroundColor: '#257062',
                        borderRadius: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        boxShadow: `1px 2px 5px #000`,
                    }}
                    onClick={() => signOut()} >
                    <p style={{
                        color: 'white',
                        marginLeft: '10%',
                        marginRight: '10%'
                    }} >
                        Logga ut
                    </p>
                </div>
            </div>
            </div>
        </div>
    )
}

function OrderAdmin({ signOut, user }) {
    const navigate = useNavigate()
    const [orders, setOrders] = useState()
    const [products, setProducts] = useState() 
    const [status, setStatus] = useState('COMPLETED')

    async function FetchCustomer({customerId}) {
        console.log('id', customerId)
        const customer = await (await API.graphql({
            query: queries.getCustomer,
            variables: { id: customerId },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })).data.getCustomer
        console.log('c', customer)
        return(customer)
    }
    
    async function FetchOrders() {
        const orders = await (await API.graphql({
            query: queries.ordersBySTATUSAndORDER_DATE,
            variables: { 
                STATUS: status, 
                limit: 10, 
                sortDirection: "ASC" },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            })).data.ordersBySTATUSAndORDER_DATE.items
            console.log('o', orders)
            const ordersAndCustomers = await Promise.all(orders.map(async order => {
                const customer = await FetchCustomer({customerId: order.customerID})
                let ids = []
                order.Products.items.map(product => ids.push(product.productId))
                return {order: order, customer: customer, productIds: ids}
            }))
            console.log('00', ordersAndCustomers)
            setOrders(ordersAndCustomers)
    }

    async function FetchProducts() {
        const allProducts = await (await API.graphql({
            query: queries.listProducts
        })).data.listProducts.items
        setProducts(allProducts)
    }
        
    async function GetUser() {
        try {
            const authUser = await Auth.currentAuthenticatedUser()
            if (authUser.signInUserSession.accessToken.payload["cognito:groups"].includes("Admin")) {
                FetchOrders()
                return
            }
            navigate('/')
        } catch (error) {
            navigate('/')
        }
    }

    useEffect(() => {
        FetchProducts()
    }, [])

    useEffect(() => {
        GetUser()
    }, [status])

    function DownloadCredentials({customer, column, row}) {
        const rows = 3
        const paperWitdh = 210
        const startingPointX = (paperWitdh/rows)*column+10
        const doc = new jsPDF();

        doc.text(customer.FIRST_NAME+' '+customer.LAST_NAME, startingPointX, 10*row);
        doc.text(customer.STREET_ADDRESS, startingPointX, 20*row);
        doc.text(customer.POST_NUMBER+' '+customer.CITY, startingPointX, 30*row);
        doc.save("a4.pdf");
    }

    function PrintAll(orders) {
        const rows = 10
        const paperHeight = 290
        const columns = 3
        const paperWitdh = 210
        
        const doc = new jsPDF();
        let row = 0
        let column = 0
        orders.map(order => {
            console.log('row', row, 'column', column)
            const startingPointX = ((paperWitdh/columns)*column+10)
            const startingPointY = (paperHeight/rows)*row+10
            doc.text(order.customer.FIRST_NAME+' '+order.customer.LAST_NAME, startingPointX, startingPointY+10);
            doc.text(order.customer.STREET_ADDRESS, startingPointX, startingPointY+20);
            doc.text(order.customer.POST_NUMBER+' '+order.customer.CITY, startingPointX, startingPointY+30);
            if (column === columns-1) {
                row = row + 1
                column = 0
                return
            }
            column = column + 1
        })
        doc.save("a4.pdf");

    } 

    const download = function (data) {
 
        // Creating a Blob for having a csv file format
        // and passing the data with type
        const blob = new Blob([data], { type: 'text/csv' });
     
        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob)
     
        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a')
     
        // Passing the blob downloading url
        a.setAttribute('href', url)
     
        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('download', 'fraktsedel.csv'); //lägg till datum i namn?
     
        // Performing a download with click
        a.click()
    }
     
    const csvmaker = function (data) {
        // Empty array for storing the values
        let csvRows = [];
     
        // Headers is basically a keys of an
        // object which is id, name, and
        // profession
        const headers = Object.keys(data[0]);
     
        // As for making csv format, headers
        // must be separated by comma and
        // pushing it into array
        csvRows.push(headers.join(';'));
     
        // Pushing Object values into array
        // with comma separation
        data.map(d => csvRows.push(Object.values(d).join(';')))
    
        console.log(csvRows)
        // Returning the array joining with new line
        return csvRows.join('\n')
    }
     
    const get = async function (orders) {
        function CalculatePackaging(order) {
            const packages = []
            order.productIds.map(productId => (
                products.map(product => (
                    product.id === productId ? 
                        packages.push(product.CATEGORY === 'START_KIT' ? 'BOX1' : product.CATEGORY === 'SUBSCRIPTION' ? 'ENV1' : product.CATEGORY === 'SEASONAL' ? 'BOX1ENV1' : '') : null
                ) 
            ))

            )
            let firstOrder = ''
            const finalPackages = []
            packages.map((p, index) => {
                if (firstOrder) {
                    if (firstOrder === 'BOX1') {
                        if (p === 'BOX1') {
                            firstOrder = ''
                            finalPackages.push('BOX2')
                        }
                        if (p === 'ENV1') {
                            firstOrder = ''
                            finalPackages.push('BOX1ENV1')
                        }
                    }
                    else if (firstOrder === 'ENV1') {
                        if (p === 'BOX1') {
                            firstOrder = ''
                            finalPackages.push('BOX1ENV1')
                        }
                    }
                    else {
                        finalPackages.push(packages[index-1])
                        finalPackages.push(p)
                    }
                }
                else {
                    if (index === packages.length-1) {
                        finalPackages.push(p)
                    }
                    else {
                        firstOrder = p
                    }
                }
            })
            return(finalPackages)

            /* 'ENV1',
            'BOX1',
            'BOX2',
            'BOX1ENV1' */
        }
        orders.map(o => o.numberOfPackages = CalculatePackaging(o))
        const data = []
        orders.map(order => (
            order.numberOfPackages.map(p => (
                data.push({
                    "Template": p, //gör en för rakbladen och en för hyvlarna
                    "Quantity": 1,
                    "Receiver First and last name (Private only)": order.customer.FIRST_NAME+' '+order.customer.LAST_NAME,
                    "Receiver C/O (Private only)": "",
                    "Receiver Company name (Company only)": "",
                    "Receiver Attn (Company only)": "",
                    "Receiver E-mail": order.customer.EMAIL,
                    "Receiver Phone country code": "",
                    "Receiver Phone": "",
                    "Receiver Address 1": order.customer.STREET_ADDRESS,
                    "Receiver Address 2": "",
                    "Receiver Door Code": "",
                    "Receiver Postal code": order.customer.POST_NUMBER,
                    "Receiver Postal city": order.customer.CITY,
                    "Receiver Country code": "SE",
                    "Shipment reference": "",
                    "Shipment description": ""
                })
            ))
        ))
        /* const data = [{
            id: 1,
            name: "Geeks",
            profession: "developer"
        },{
            id: 2,
            name: "nerd",
            profession: "guard"
        }] */
        
        const csvdata = csvmaker(data);
        console.log('d', data)
        //console.log(csvdata)
        download(csvdata);
    }

    async function PayOrder({order_id, previous_order_id}) {
        //const url = 'https://7wdysqanssptl2bjms6tdb2dq40qpshm.lambda-url.eu-north-1.on.aws/' //staging
        const url = 'https://dm7kwkmn7yobxvvgjzlpupudrm0heiwx.lambda-url.eu-north-1.on.aws/' //prod
        const response = await fetch(
            url
                +'?order_id='+order_id
                +'&previous_order_id='+previous_order_id
        );
        
    }

    async function UpdateNewOrder({amount, currency, customer_id, futureProductIDs, nextDelivery, futureTotalPrice, order_id, previous_order_id}) {
        //const url = 'https://5jgjkmtztmroj7gziiq4k32uc40llzeo.lambda-url.eu-north-1.on.aws/' //staging
        const url = 'https://znepylxyprl75fg4yhkmmxgkea0iwlrz.lambda-url.eu-north-1.on.aws/' //prod
        //console.log(amount, currency, customer_id, futureProductIDs, nextOrderDate, futureTotalPrice, order_id)
        const nextOrderDate = JSON.stringify(moment().add(nextDelivery, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ')).replace('+', '%2b')
        const response = await fetch(
            url
                +'?amount='+amount
                +'&currency='+currency
                +'&customer_id='+customer_id
                +'&future_products='+JSON.stringify(futureProductIDs)
                +'&frequency='+nextOrderDate
                +'&future_price='+futureTotalPrice
                +'&order_id='+order_id
                +'&recurring='+nextDelivery
            );
        const json = await response.json();
        await PayOrder({order_id: json.id, previous_order_id: previous_order_id})
    }

    async function MarkAsSent({order_id, setStatus}) {
        try {
            const details = {
                id: order_id,
                STATUS: 'DELIVERED'
            }
            await API.graphql({
                query: mutations.updateOrder,
                variables: { input: details },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            })
            setStatus('DELIVERED')
        } catch (error) {
            console.log('error in MarkAsSent', error)
        }
    }

    function DisplayOrder({order, customer, productIds}) {
        const [status, setStatus] = useState(order.STATUS)
        function Products({product}) {
            return(
                <div>
                    <p>
                        {product.NAME}
                    </p>
                </div>
            )
        }

        function PayInformation({order}) {
            return(
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '25%',
                }} >
                    <p>
                        {moment(order.ORDER_DATE).format('YYYY-MM-DD')}
                    </p>
                    <p>
                        {order.AMOUNT} {order.CURRENCY}
                    </p>
                    <p>
                        {'Order status '+order.STATUS}
                    </p>
                </div>
            )
        }

        function ContactInformation({customer}) {
            return(
                <div style={{
                    width: '35%'
                }} >
                    <p>
                        {customer.FIRST_NAME} {customer.LAST_NAME}
                    </p>
                    <p>
                        {customer.STREET_ADDRESS}
                    </p>
                    <p>
                        {customer.POST_NUMBER} {customer.CITY}
                    </p>
                    <p>
                        {customer.COUNTRY}
                    </p>
                </div>
            )
        }

        function ProductInformation({productIds}) {
            return(
                <div style={{
                    width: '25%'
                }} >
                     {productIds.map((productId, index) => (
                        products.map(product => (
                            product.id === productId ?
                                <p key={index+product.id} >
                                    {product.NAME}
                                </p> : null
                        ) 
                    ))
                    )}

                </div>
            )
        }
        
        return(
            <div style={{
                display: 'flex',
                width: '90vw',
                justifyContent: 'space-between',
                marginTop: '5vh'
            }} >
                <div style={{
                    width: '15%'
                }} >
                    {/* {order.Products.items.map(product => 
                        <Products key={product.productId} product={product.product} />
                    )} */}
                </div>
                <PayInformation order={order} />
                <ProductInformation productIds={productIds} />
                <ContactInformation customer={customer} />
                {status === 'DELIVERED' ? 
                    <p>Skickad</p> :
                    <div style={{
                        backgroundColor: '#257062',
                        borderRadius: '25px',
                        //paddingLeft: 10,
                        //paddingRight: 10,
                        display: 'flex',
                        paddingTop: 1,
                        paddingBottom: 1,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        boxShadow: `1px 2px 5px #000`,
                        position: DynamicSize({large: 'absolute', medium: 'absolute', mobile: 'relative'}),
                        right: DynamicSize({large: '5%', medium: '5%', mobile: null}),
                        width: DynamicSize({large: '10%', medium: '10%', mobile: '50%'}),
                        
                    }} 
                    onClick={() => 
                        status === 'COMPLETED' ? 
                            MarkAsSent({order_id: order.id, setStatus: setStatus}) 
                            :
                            UpdateNewOrder({
                                amount: order.AMOUNT,
                                currency: order.CURRENCY, 
                                customer_id: customer.id,
                                futureProductIDs: productIds,
                                nextDelivery: order.RECURRING,
                                futureTotalPrice: order.AMOUNT, 
                                order_id: order.id,
                                previous_order_id: order.PREVIOUS_ORDER_ID
                            })
                        } >
                        <p style={{
                            color: 'white',
                            alignSelf: 'center',
                            marginLeft: '10%',
                            marginRight: '10%'
                        }} >
                            {status === 'COMPLETED' ? 'Markera som skickad' : 'Uppdatera order'} 
                        </p>
                        {/* {status === 'COMPLETED' ? 
                            <button onClick={() => get(order)} >Fraktsedel</button>
                        : null} */}
                    </div>
                }
            </div>
        )
    }

    function Buttons({title, STATUS}) {
        return(
            <div 
                style={{
                    backgroundColor: status === STATUS ? '#257062' : null,
                    borderRadius: '25px',
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    boxShadow: `1px 2px 5px #000`,
                }}
                onClick={() => setStatus(STATUS)}
                >
                
                <p style={{
                    marginTop: '10%',
                    marginBottom: '10%',
                    color: status === STATUS ? 'white' : 'black'
                }} >
                    {title}
                </p>
            </div>
        )
    }

   if (!orders || !products) {
    return(
        <div style={{
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
        }} >
            <Spinner />
        </div>
    )
   } 

    return (
        
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
        }} >
        <Part1 user={user} />
        <div style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'space-evenly',
            marginBottom: 10
        }} >
            <Buttons title={'Created'} STATUS={'CREATED'} />
            <Buttons title={'Completed'} STATUS={'COMPLETED'} />
            <Buttons title={'Delivered'} STATUS={'DELIVERED'} />
        </div>
        {status === 'COMPLETED' ? <button onClick={() => get(orders)} >Skriv ut fraktsedel</button> : null}
            <div>
                {orders.map(order => 
                    <DisplayOrder key={order.order.id} order={order.order} customer={order.customer} productIds={order.productIds} />
                    )}
            </div>
        
        </div>
    );
}
export default withAuthenticator(OrderAdmin);