import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import  { Amplify, Analytics, Auth } from 'aws-amplify';
import awsmobile from './aws-exports';
import ScrollToTop from './components/functions/scrollToTop';

//import './index.css';
import Layout from './navigation/layout';
import Skagg from './components/skagg';
import Frequency from './components/frequency'
import Checkout from './components/checkout';
import OrderAdmin from './components/order_admin';
import Products from './components/screens/products';
import ReferAFriend from './components/screens/referafriend';
import PrivacyPolicy from './components/screens/privacy_policy';
import HowToShave from './components/screens/how_to_shave';
import HowItWorks from './components/screens/how_it_works';
import Contact from './components/screens/contact';
import OrderStep1 from './components/screens/order_step_1';
import UserPage from './components/screens/user_page';
import SignUpCustomer from './components/screens/sign_up_customer'
import AboutUs from './components/screens/about_us';
import Subscription from './components/screens/subscription';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
Amplify.configure(awsmobile);

Analytics.autoTrack('pageView', { //https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js/
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: 'pageView',
  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  //type: 'multiPageApp',
  type: 'SPA',
  provider: 'AWSPinpoint',
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  }
});
Analytics.autoTrack('event', {
  enable: true,
  // OPTIONAL, events you want to track, by default is 'click'
  events: ['click'],
  selectorPrefix: 'data-amplify-analytics-',
  provider: 'AWSPinpoint',
});

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Skagg/>} />
          <Route path="/products" element={<Products/>} />
          <Route path="/how-to-shave" element={<HowToShave/>} />
          <Route path="/how-it-works" element={<HowItWorks/>} />
          <Route path="/refer-a-friend" element={<ReferAFriend/>} />
          <Route path="/get-started/step-1" element={<OrderStep1/>} />
          <Route path="/get-started/step-2" element={<Frequency/>} />

          {/* <Route path="/order" element={<Order/>} /> */}
          <Route path="/checkout" element={<Checkout/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/admin" element={<OrderAdmin/>} />
          <Route path="/user" element={<UserPage/>} />
          <Route path="/sign-up-customer" element={<SignUpCustomer/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/start-subscription" element={<Subscription/>} />
        </Route>
      </Routes>
    </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();