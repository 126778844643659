import {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
//import logo from '../assets/SVALL_LOGO.svg';

//import logo from './logo.svg';
import { API } from "aws-amplify";
import * as queries from '../../graphql/queries';

import { useNavigate } from 'react-router-dom';

import NavigatorBar from '../navigator_bar';
import Footer from '../footer';
import DynamicSize from '../functions/dynamic_size';

function HowToShave() {
  const navigate = useNavigate()
  const [products, setProducts] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [cookie, setCookie, removeCookie] = useCookies(['shoppingBag', 'product'])

 
  function Steps({photo, header, text}) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: DynamicSize({large: '20vw', medium: '20vw', mobile: '100vw'}),
      }} >
        <img 
          src={photo}
          style={{
            height: DynamicSize({large: 200, medium: 130, mobile: 200})
          }} />
        <p style={{
          fontWeight: 'bold'
        }} >
          {header}
        </p>
        <p style={{
          marginLeft: DynamicSize({large: 0, medium: 0, mobile: '10vw'}),
          marginRight: DynamicSize({large: 0, medium: 0, mobile: '10vw'}),
          textAlign: 'center'
        }} >
          {text}
        </p>
      </div>
    )
  }

  return (
    <div 
    //className='App'
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw'
    }}
    >
      <NavigatorBar />
      <div style={{
        paddingLeft: '10vw',
        paddingRight: '10vw',
        marginTop: 30
      }} >
        <h3 style={{
          fontSize: DynamicSize({large: 20, medium: 18, mobile: 15})
        }} >
        "Att raka som en barberare har aldrig varit enklare. En säkerhetshyvel är ett rakredskap med en skyddsanordning placerad mellan kanten på bladet och huden. Det ursprungliga syftet med dessa skyddsanordningar var att minska den skicklighet som krävs för att raka utan skador och därigenom minska beroendet av professionella barberare."
        </h3>
      </div>

      <div style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: DynamicSize({large: 'row', medium: 'row', mobile: 'column'}),
        marginTop: 50
      }} >
        <Steps photo={"assets/shave/shave_1.png"} header={"Håll rakhyveln i 30°"} text={"Den här vinkeln gör att bladet fungerar mer effektivt"} />
        <Steps photo={"assets/shave/shave_2.png"} header={"Raka i korta drag"} text={"Drag mellan 1-3cm fungerar bäst"} />
        <Steps photo={"assets/shave/shave_3.png"} header={"Raka med stråna"} text={"Rakning i hårets riktning kommer minska irritation"} />
        <Steps photo={"assets/shave/shave_4.png"} header={"Applicera ett lätt tryck"} text={"Låt tyngden av rakhyveln göra det mesta av jobbet"} />

      </div>


      <Footer />
    </div>
  );
}



export default HowToShave;