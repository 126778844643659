import DynamicSize from "./functions/dynamic_size"

function SkaggSection1() {
    
    return(
        <div style={{
          position: 'relative',
          //https://www.freecodecamp.org/news/how-to-understand-css-position-absolute-once-and-for-all-b71ca10cd3fd/
        }} >
            <img
              //src="assets/aleksandar-popovski-Jy4ELSGPHTc-unsplash.jpeg"
              src="assets/skagg_poster_website.jpeg"
              alt="Skägg rakhyvlar"
              
              style={{
                width: '100%',
                opacity: '70%',
              }} />
               
            <div style={{
              position: 'absolute',
              //top: 0,
              //top: DynamicSize({large: '40vw', medium: '40vw', mobile: '48vw'}),
              right: '5%',
              width: '35%',
              bottom: '10%'
            }} >
              {/* <h3 style={{
                fontSize: DynamicSize({large: '5vmin', medium: 30, mobile: 15}),
                //textTransform: 'uppercase',
                fontFamily: 'Prompt',
                fontWeight: '200',
                color: '#257062',
                fontStyle: 'italic',
                textAlign: 'end'
              }} >
                Godkänd av din farfars generation!
              </h3> */}
              <h3 style={{
                fontSize: DynamicSize({large: '3vmin', medium: 15, mobile: 12}),
                fontFamily: 'Prompt',
                fontWeight: '200',
                color: '#257062',
                fontStyle: 'italic',
                textAlign: 'end'
              }} >
                Tidlösa rakprodukter i riktiga material, för en klassisk rakupplevelse
              </h3>

            </div>
        </div>
    )
}
export default SkaggSection1;