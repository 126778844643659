/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      PRICE
      orders {
        items {
          id
          productId
          orderId
          product {
            id
            PRICE
            NAME
            DESCRIPTION
            CATEGORY
            S3FILE
            S3FILE_EXTERNAL
            productNumber
            createdAt
            updatedAt
          }
          order {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          authOwner
        }
        nextToken
      }
      NAME
      DESCRIPTION
      CATEGORY
      S3FILE
      S3FILE_EXTERNAL
      productNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      PRICE
      orders {
        items {
          id
          productId
          orderId
          product {
            id
            PRICE
            NAME
            DESCRIPTION
            CATEGORY
            S3FILE
            S3FILE_EXTERNAL
            productNumber
            createdAt
            updatedAt
          }
          order {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          authOwner
        }
        nextToken
      }
      NAME
      DESCRIPTION
      CATEGORY
      S3FILE
      S3FILE_EXTERNAL
      productNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      PRICE
      orders {
        items {
          id
          productId
          orderId
          product {
            id
            PRICE
            NAME
            DESCRIPTION
            CATEGORY
            S3FILE
            S3FILE_EXTERNAL
            productNumber
            createdAt
            updatedAt
          }
          order {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          authOwner
        }
        nextToken
      }
      NAME
      DESCRIPTION
      CATEGORY
      S3FILE
      S3FILE_EXTERNAL
      productNumber
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      EMAIL
      FIRST_NAME
      STREET_ADDRESS
      POST_NUMBER
      COUNTRY
      Orders {
        items {
          id
          AMOUNT
          CURRENCY
          customerID
          Products {
            nextToken
          }
          ORDER_DATE
          PAYMENT_PROVIDER
          STATUS
          PREVIOUS_ORDER_ID
          ORDER_ID
          authOwner
          RECURRING
          DISCOUNT_CODE
          createdAt
          updatedAt
        }
        nextToken
      }
      PHONE_NUMBER
      APARTMENT_NUMBER
      CITY
      LAST_NAME
      EMAIL_OFFERS
      authOwner
      cognitoUserName
      REVOLUT_CUSTOMER_ID
      PAYMENT_PROVIDER
      REVOLUT_PAYMENT_METHOD_ID
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      EMAIL
      FIRST_NAME
      STREET_ADDRESS
      POST_NUMBER
      COUNTRY
      Orders {
        items {
          id
          AMOUNT
          CURRENCY
          customerID
          Products {
            nextToken
          }
          ORDER_DATE
          PAYMENT_PROVIDER
          STATUS
          PREVIOUS_ORDER_ID
          ORDER_ID
          authOwner
          RECURRING
          DISCOUNT_CODE
          createdAt
          updatedAt
        }
        nextToken
      }
      PHONE_NUMBER
      APARTMENT_NUMBER
      CITY
      LAST_NAME
      EMAIL_OFFERS
      authOwner
      cognitoUserName
      REVOLUT_CUSTOMER_ID
      PAYMENT_PROVIDER
      REVOLUT_PAYMENT_METHOD_ID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      EMAIL
      FIRST_NAME
      STREET_ADDRESS
      POST_NUMBER
      COUNTRY
      Orders {
        items {
          id
          AMOUNT
          CURRENCY
          customerID
          Products {
            nextToken
          }
          ORDER_DATE
          PAYMENT_PROVIDER
          STATUS
          PREVIOUS_ORDER_ID
          ORDER_ID
          authOwner
          RECURRING
          DISCOUNT_CODE
          createdAt
          updatedAt
        }
        nextToken
      }
      PHONE_NUMBER
      APARTMENT_NUMBER
      CITY
      LAST_NAME
      EMAIL_OFFERS
      authOwner
      cognitoUserName
      REVOLUT_CUSTOMER_ID
      PAYMENT_PROVIDER
      REVOLUT_PAYMENT_METHOD_ID
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      AMOUNT
      CURRENCY
      customerID
      Products {
        items {
          id
          productId
          orderId
          product {
            id
            PRICE
            NAME
            DESCRIPTION
            CATEGORY
            S3FILE
            S3FILE_EXTERNAL
            productNumber
            createdAt
            updatedAt
          }
          order {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          authOwner
        }
        nextToken
      }
      ORDER_DATE
      PAYMENT_PROVIDER
      STATUS
      PREVIOUS_ORDER_ID
      ORDER_ID
      authOwner
      RECURRING
      DISCOUNT_CODE
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      AMOUNT
      CURRENCY
      customerID
      Products {
        items {
          id
          productId
          orderId
          product {
            id
            PRICE
            NAME
            DESCRIPTION
            CATEGORY
            S3FILE
            S3FILE_EXTERNAL
            productNumber
            createdAt
            updatedAt
          }
          order {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          authOwner
        }
        nextToken
      }
      ORDER_DATE
      PAYMENT_PROVIDER
      STATUS
      PREVIOUS_ORDER_ID
      ORDER_ID
      authOwner
      RECURRING
      DISCOUNT_CODE
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      AMOUNT
      CURRENCY
      customerID
      Products {
        items {
          id
          productId
          orderId
          product {
            id
            PRICE
            NAME
            DESCRIPTION
            CATEGORY
            S3FILE
            S3FILE_EXTERNAL
            productNumber
            createdAt
            updatedAt
          }
          order {
            id
            AMOUNT
            CURRENCY
            customerID
            ORDER_DATE
            PAYMENT_PROVIDER
            STATUS
            PREVIOUS_ORDER_ID
            ORDER_ID
            authOwner
            RECURRING
            DISCOUNT_CODE
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          authOwner
        }
        nextToken
      }
      ORDER_DATE
      PAYMENT_PROVIDER
      STATUS
      PREVIOUS_ORDER_ID
      ORDER_ID
      authOwner
      RECURRING
      DISCOUNT_CODE
      createdAt
      updatedAt
    }
  }
`;
export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      productId
      orderId
      product {
        id
        PRICE
        orders {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        NAME
        DESCRIPTION
        CATEGORY
        S3FILE
        S3FILE_EXTERNAL
        productNumber
        createdAt
        updatedAt
      }
      order {
        id
        AMOUNT
        CURRENCY
        customerID
        Products {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        ORDER_DATE
        PAYMENT_PROVIDER
        STATUS
        PREVIOUS_ORDER_ID
        ORDER_ID
        authOwner
        RECURRING
        DISCOUNT_CODE
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      authOwner
    }
  }
`;
export const updateOrderProduct = /* GraphQL */ `
  mutation UpdateOrderProduct(
    $input: UpdateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    updateOrderProduct(input: $input, condition: $condition) {
      id
      productId
      orderId
      product {
        id
        PRICE
        orders {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        NAME
        DESCRIPTION
        CATEGORY
        S3FILE
        S3FILE_EXTERNAL
        productNumber
        createdAt
        updatedAt
      }
      order {
        id
        AMOUNT
        CURRENCY
        customerID
        Products {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        ORDER_DATE
        PAYMENT_PROVIDER
        STATUS
        PREVIOUS_ORDER_ID
        ORDER_ID
        authOwner
        RECURRING
        DISCOUNT_CODE
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      authOwner
    }
  }
`;
export const deleteOrderProduct = /* GraphQL */ `
  mutation DeleteOrderProduct(
    $input: DeleteOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    deleteOrderProduct(input: $input, condition: $condition) {
      id
      productId
      orderId
      product {
        id
        PRICE
        orders {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        NAME
        DESCRIPTION
        CATEGORY
        S3FILE
        S3FILE_EXTERNAL
        productNumber
        createdAt
        updatedAt
      }
      order {
        id
        AMOUNT
        CURRENCY
        customerID
        Products {
          items {
            id
            productId
            orderId
            createdAt
            updatedAt
            authOwner
          }
          nextToken
        }
        ORDER_DATE
        PAYMENT_PROVIDER
        STATUS
        PREVIOUS_ORDER_ID
        ORDER_ID
        authOwner
        RECURRING
        DISCOUNT_CODE
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      authOwner
    }
  }
`;
