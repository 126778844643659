import {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';

import { API } from "aws-amplify";
import * as queries from '../graphql/queries';

import { useNavigate } from 'react-router-dom';
import '../App.css';
import NavigatorBar from './navigator_bar';
import ProductBox from './product_box';
import Footer from './footer';
import SkaggSection1 from './skagg_section_1'
import SkaggSection2 from './skagg_section_2'
import SkaggSection3 from './skagg_section_3'
import SkaggSection4 from './skagg_section_4'
import Banner from './banner';

function Skagg() {
  const navigate = useNavigate()
  const [products, setProducts] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [cookie, setCookie] = useCookies(['shoppingBag', 'product'])
  const delay = 3500

  async function GetProducts() {
    try {
      const fetchProducts = await API.graphql({
        query: queries.listProducts, variables: {filter: {CATEGORY: {eq: "START_KIT"}}}
      });
      console.log('fetch', fetchProducts)
      const sortedProducts = fetchProducts.data.listProducts.items.sort((a, b) => a.productNumber - b.productNumber)
      setProducts(sortedProducts)
      console.log(products)
      setSelectedProduct(sortedProducts[0].id)
    } catch (error) {
      console.log('error', error)
    }
  }

  function InstagramFeed() {
    return(
      <script type="text/javascript">
      {function(){
      var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
      i.src="https://cdn.curator.io/published/bd2bfdb5-8265-4659-8a52-6774c7b15bab.js";
      e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
      }()};
      </script>
    )
  }

  /* async function GetUserData() {
    const anonymousUser = await Auth.currentCredentials()
    console.log(anonymousUser)
  } */

  useEffect(() => {
    GetProducts()
  }, [])

  function Click() {
    if (!selectedProduct) {
      return
    }
    let changeOrder = true
    if (cookie.product) {
      cookie.product.map(p => {
        if (JSON.parse(p).product === selectedProduct) {
          changeOrder = false
          return;
        }
      })
    }
    if (changeOrder) {
      const product = {
        product: selectedProduct,
        frequency: 0
      }
      setCookie('product', cookie.product ? [...cookie.product, JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'})
    }
    navigate('/get-started/step-2')
  }

  const data = [{
    header: "Alltid fri frakt"
  },{
    header: "Produkter i naturmaterial"
  }]

  if (!products) {
    return null
  }

  return (
    <div 
    //className='App'
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw'
    }}
    >
      <NavigatorBar />
      <div>
        <SkaggSection1/>
        {/* <div style={{
          position: 'absolute',
          top: 150,
          left: '5vw'
        }} >
        <img 
          src={logo}
          alt="logo"
          style={{
            
            top: 1500,
            width: '17vw'
          }} />
        </div> */}
      </div>
        <div style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          //backgroundColor: '#257062',
          borderBottomStyle: 'solid'
        }} >
          <SkaggSection2 />
          {/* <h3 style={{
            textTransform: 'uppercase',
            fontSize: 15,
            fontFamily: 'Prompt'
          }} >
          Alltid fri frakt
        </h3> */}
        </div>
        {/* <Banner/> */}


        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f0efeb',
          //justifyContent: 'center',
          marginBottom: '5vh',
          paddingBottom: '5vh'
          //width: '100vw'
        }} >
          <h3 style={{
            color: 'black',
            fontSize: '70px',
            fontFamily: 'Prompt'
          }} >
            Startkit
          </h3>
          <h3 style={{
            color: 'black',
            fontSize: '30px',
            marginBottom: '5vh',
            fontFamily: 'Prompt',
            fontWeight: '200'
          }} >
            Rakhyvel + 2x rakblad
          </h3>
          <div 
            className="scrollbar"
            style={{
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'scroll',
              width: '100vw',
              maxWidth: '100%',
              scrollbarWidth: 0,
              marginBottom: '5vh',
          }} >
            {products.map((product, index) => 
              <ProductBox 
                key={product.id}
                id={product.id}
                price={product.PRICE}
                name={product.NAME}
                s3file={product.S3FILE}
                selectedProduct={selectedProduct} 
                setSelectedProduct={setSelectedProduct}
                index={index}
                length={products.length} />
            )}
          </div>

          <div style={{
            backgroundColor: '#257062',
            height: '60px',
            width: '300px',
            maxWidth: '90vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: `1px 2px 5px #000`,
            cursor: 'pointer'

          }}
            onClick={() => Click()} >
            <h3 style={{
              textTransform: 'uppercase',
              fontFamily: 'Prompt',
              color: 'white'
            }} >
              Nästa steg
            </h3>
          </div>

        </div> 
      <SkaggSection3 />
      <SkaggSection4 />
      <div style={{
        marginTop: 100
      }} >
        <InstagramFeed/>
        <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
      </div>



      <Footer />
    </div>
  );
}
export default Skagg;