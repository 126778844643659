import DynamicSize from "./functions/dynamic_size"
import { GiSharpShuriken } from 'react-icons/gi'
import { BiMailSend } from 'react-icons/bi'
import { RiPlantLine } from 'react-icons/ri'

function SkaggSection3() {
    function Boxes({icon, header, text}) {
        return(
          <div style={{
            width: '100%'
          }} >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }} >
              <div style={{
                marginRight: 10
              }} >
                {icon}
              </div>
              <div style={{
    
              }} >
                <h3>
                  {header}
                </h3>
                <p>
                  {text}
                </p>
              </div>
            </div>
          </div>
        )
      }
    return(
        <div style={{
            display: 'flex',
            flexDirection: DynamicSize({large: 'row', medium: 'row', mobile: 'column'}),
            justifyContent: 'space-evenly',
            //height: '50vh'
          }} >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              //height: '100%',
              width: DynamicSize({large: '50%', medium: '50%', mobile: '100%'}),
              paddingLeft: 10,
              paddingRight: 10
            }} >
              <Boxes
                icon={<GiSharpShuriken size={'30px'} />}
                header={'DIN RAKNING HAR ALDRIG VARIT VASSARE'}
                text={'Ett sylvasst dubbelsidigt rakblad är allt du behöver, inget mer inget mindre'}
                //text={'Ett sylvasst dubbelsidigt rakblad som ger dig en riktigt nära rakning. Dude, if you have pimples, this is not for you'}
                />
              <Boxes
                icon={<BiMailSend size={'30px'} />}
                header={'SMIDIG PRENUMERATION'}
                text={'Vi säkerställer att du alltid rakar dig med vassa blad. Nya blad kommer i brevlådan anpassat efter dina rakrutiner, pausa eller avsluta när du vill'}
                />
              <Boxes
                icon={<RiPlantLine size={'30px'} />}
                header={'HÅLLBARA PRODUKTER'}
                text={'Plast är inte riktigt vår grej, våra produkter är gjorda till 100% av riktiga material, som trä och metall'}
                //text={'Plast känns 1998, våra produkter innehåller 100% metall'}
                />
  
            </div>
            <img
              src='assets/DSC_1148.jpeg'
              style={{
                width: DynamicSize({large: '45vw', medium: '45vw', mobile: '95vw'}),
                alignSelf: DynamicSize({large: 'center', medium: 'center', mobile: 'center'}),
                //marginLeft: DynamicSize({large: 0, medium: 0, mobile: 15}),
                //marginRight: DynamicSize({large: 0, medium: 0, mobile: 15}),
                objectFit: 'contain'
              }}
              alt={'placeholder'}
            />
          </div>
    )
}
export default SkaggSection3;