import {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
//import logo from '../assets/SVALL_LOGO.svg';

//import logo from './logo.svg';
import { API } from "aws-amplify";
import * as queries from '../../graphql/queries';

import { useNavigate } from 'react-router-dom';

import { BsMailbox } from "react-icons/bs";
import { GiBeard, GiRazor } from "react-icons/gi";

import NavigatorBar from '../navigator_bar';
import ProductBox from '../product_box';
import Footer from '../footer';
import DynamicSize from '../functions/dynamic_size';

function HowItWorks() {
  const navigate = useNavigate()
  const [cookie, setCookie, removeCookie] = useCookies(['shoppingBag', 'product'])

  function Section({question, answers}) {
    
    return(
      <div style={{
        marginBottom: 40
      }} >
        <p style={{
          fontWeight: 'bold'
        }} >
          {question}
        </p>
        {answers.map(answer => 
          <p style={{
            fontStyle: 'italic'
          }} >
            {answer}
          </p>
        )}
      </div>
    )
  } 

  function Steps({header, icon, text}) {
    return(
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: DynamicSize({large: '20%', medium: '20%', mobile: '90vw'}),
        alignItems: 'center',
        }} >
          {icon}
          <h3 style={{
            marginTop: 30,
            textAlign: 'center',
            width: '100%',
          }} >
            {header}
          </h3>
          <p style={{
            textAlign: 'center',
            marginTop: DynamicSize({mobile: 0}),
          }} >
            {text}
          </p>
      </div>
    )
  }

  function click() {
    navigate('/get-started/step-1')
  }

  return (
    <div 
    //className='App'
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw'
    }}
    >
      <NavigatorBar />

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }} >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '80%',
          marginBottom: '10vh',
        }} >
          <p>
            Så fungerar det
          </p>
          <h3>
            Vägen till en bättre rakning
          </h3>
          {/* <p>
            Placeholder text 
          </p> */}
          <div style={{
            display: 'flex',
            flexDirection: DynamicSize({large: 'row', medium: 'row', mobile: 'column'}),
            justifyContent: 'space-evenly',
            alignItems: DynamicSize({mobile: 'center'}),
            width: '100%',
            marginTop: '5vh',
            marginBottom: '5vh'
          }} >
            <Steps 
              header={'Välj ett startpaket'} 
              icon={<GiRazor size={30} />} 
              text={'Första leveransen ger dig allt du behöver för att komma igång'} />
            <Steps 
              header={'Välj frekvens'} 
              icon={<BsMailbox size={30} />} 
              text={'Nya rakblad skickas hem när du behöver nya'}  />
            <Steps 
              header={'Raka'} 
              icon={<GiBeard size={30} />} 
              text={'Njut av en skarpare och bättre rakning!'} />
          </div>
          <div style={{
            backgroundColor: '#257062',
            height: '60px',
            width: '300px',
            maxWidth: '90vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: `1px 2px 5px #000`,
          }}
            onClick={() => click()} >
            <h3 style={{
              textTransform: 'uppercase',
              fontFamily: 'Prompt',
              color: 'white'
            }} >
              Kom igång
            </h3>
          </div>
        </div>

        {/* Q&A */}
        <div style={{
          width: '80%'
        }} >
          <h3>
            Vanliga frågor
          </h3>
          <Section 
            question={'Fungerar det att använda vanligt raklödder till rakningen?'}
            answers={[
              'Ja, du kan använda vanlig rakskum eller rakgel med en säkerhetsrakhyvel. Det är dock viktigt att se till att skummet eller gelen är väl löddrad och jämnt fördelad på området du rakar. Det hjälper rakhyveln att glida smidigt över huden och förhindrar eventuella snitt eller skador.',
              'Det är också värt att notera att vissa personer föredrar att använda en traditionell raktvål eller rakkräm med en borste när de använder en säkerhetsrakhyvel. Dessa produkter är utformade för att skapa ett rikt lödder som ger en skyddande lager för rakhyveln, vilket kan resultera i en närmare och mer bekväm rakning. Det är dock en personlig preferens och du kan använda vilken rakprodukt som fungerar bäst för dig.'  
            ]}
            />
          <Section 
            question={'Är det svårare att raka sig med en säkerhetsrakhyvel än med ett flerbladssystem?'}
            answers={[
              'Att använda en säkerhetsrakhyvel kan kräva lite mer teknik och övning än att använda ett flerbladssystem, men när du väl har lärt dig tekniken ger det en närmare och mer bekväm rakning.'
            ]}
            />
          <Section 
            question={'Kommer användning av en säkerhetsrakhyvel orsaka fler skärningar och snitt?'}
            answers={[
              'Att använda en säkerhetsrakhyvel kan kräva lite mer uppmärksamhet och teknik än att använda ett flerbladssystem, men med rätt teknik kan den faktiskt vara mjukare mot din hud och resultera i färre skärningar och snitt.'
            ]}
            />
        </div>
      </div>
      <Footer />
    </div>
  );
}



export default HowItWorks;