
import { useState } from "react";
import DynamicSize from "./functions/dynamic_size";

function ProductBox({id, price, selectedProduct, setSelectedProduct, name, index, length, s3file}) {
    const [mouseOver, setMouseOver] = useState(false)

    const scrLocation = "../assets/razors/"+s3file
    const scrLocation2 = "../assets/razors/2_"+s3file

    function changeOpacity(e) {
        setMouseOver(e.type === 'mouseover' ? true : false)
        if (id === selectedProduct) {
            e.target.style.opacity = '100%';
            return;
        }
        e.target.style.opacity = e.type === 'mouseover' ? '100%' : '90%'
    }

    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: index === length-1 ? 'auto' : null,
            marginLeft: index === 0 ? 'auto' : null,
        }} >
            <div style={{
                height: DynamicSize({large: 400, medium: 300, mobile: 300}),
                width: DynamicSize({large: 400, medium: 300, mobile: 300}),
                borderRadius: DynamicSize({large: 250, medium: 200, mobile: 150}),
                marginLeft: '2.5vw',
                marginRight: '2.5vw',
                borderStyle: 'solid',
                borderWidth: id === selectedProduct ? '5px' : '0px',
                borderColor: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '5vh',
            }} >
                <div
                    style={{
                        height: '98%',
                        width: '98%',
                        borderRadius: '49%',
                        opacity: id === selectedProduct ? '100%' : '90%',
                        //backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }} 
                    onMouseOver={changeOpacity} 
                    onMouseLeave={changeOpacity} 
                    onClick={() => setSelectedProduct(id)}
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="click"
                    data-amplify-analytics-attrs={`selectedProduct:${id}`}
                    //data-amplify-analytics-attrs="attr1:attr1_value,attr2:attr2_value"
                    >
                    {
                        mouseOver ?
                        <img 
                                    src={scrLocation2}
                                    style={{
                                        height: '100%',
                                        borderRadius: '50%',
                                    }}
                                    alt="Skägg rakhyvlar" />
                            :
                            <img 
                                    src={scrLocation}
                                    style={{
                                        height: '100%',
                                        borderRadius: '50%',
                                    }}
                                    alt={name} /> 
                    }
                        {/* {
                        mouseOver ? 
                            id === '8937d774-ee01-4e82-9a13-b7ef472ec164' ?
                                <img 
                                    src="../assets/razors/IMG_5443.jpeg"
                                    style={{
                                        height: '100%',
                                        borderRadius: '50%',
                                    }}
                                    alt="razor" />
                            :
                            id === '28cebba1-11c2-4d44-b5a0-533295b43608' ?
                                <img 
                                    src="../assets/razors/IMG_5443.jpeg"
                                    style={{
                                        height: '100%',
                                        borderRadius: '50%',
                                    }}
                                    alt="razor" />
                            :
                                <img 
                                    src="../assets/razors/IMG_5443.jpeg"
                                    style={{
                                        height: '100%',
                                        borderRadius: '50%',
                                    }}
                                    alt="razor" />
                        :
                        id === '8937d774-ee01-4e82-9a13-b7ef472ec164' ?
                            <img 
                                src="../assets/razors/DSC_1136.jpeg"
                                style={{
                                    height: '100%',
                                    borderRadius: '50%',
                                }}
                                alt="razor" />
                        :
                        id === '28cebba1-11c2-4d44-b5a0-533295b43608' ?
                            <img 
                                src="../assets/razors/DSC_1130.jpeg"
                                style={{
                                    height: '100%',
                                    borderRadius: '50%',
                                }}
                            alt="razor" />
                        :
                        <img 
                        src="../assets/razors/DSC_1137.jpeg"
                        style={{
                            height: '100%',
                            borderRadius: '50%',
                        }}
                        alt="razor" />
                    } */}
                </div>
            </div>
            <h3>
                {name}
            </h3>
            <h3 style={{
                color: 'black',
                fontSize: '30px'
            }} >
                {price} Kr
            </h3>
        </div>
    )
}
export default ProductBox;