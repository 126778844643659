import DynamicSize from "./functions/dynamic_size"
import { GiSharpShuriken } from 'react-icons/gi'
import { BiMailSend } from 'react-icons/bi'
import { RiPlantLine } from 'react-icons/ri'
import { BsStarFill } from 'react-icons/bs'
import { BsStarHalf } from 'react-icons/bs'

import {useEffect, useState} from 'react'
import { API } from "aws-amplify";
import * as queries from '../graphql/queries';


import ProductBox from './product_box';

//https://tinloof.com/blog/how-to-build-an-auto-play-slideshow-with-react
function SkaggSection4() {
  const delay = 6000

  const [products, setProducts] = useState()
  const [selectedProduct, setSelectedProduct] = useState()

  async function GetProducts() {
    const fetchProducts = await API.graphql({
      query: queries.listProducts, variables: {filter: {CATEGORY: {eq: "START_KIT"}}}
    });
    setProducts(fetchProducts.data.listProducts.items.sort((a, b) => a.productNumber - b.productNumber))
  }

  useEffect(() => {
    GetProducts()
  }, [])

  if (!products) {
    return null
  }
  
  const reviews = [{
    header: '"En helt ny(gammal) rakupplevelse"',
    text: 'Känns nytt att gå tillbaka till det traditionella - på ett bra sätt. Istället för all plast och massa blad där allt kloggar igen använder man sylvassa blad och en hyvel man kan snurra på och använda båda sidor av. Smidigt och snyggt! Känns plastigt med mina gamla hyvlar.',
    name: 'Oscar F.',
    rating: 4.5
  },{
    header: '"Skarpaste bladen"',
    text: 'Går dubbelt så smidigt att raka sig. Men man behöver se upp, det är vasst.',
    name: 'Anton K.',
    rating: 5
  },{
    header: '"Min morfar köpte också en!"',
    text: 'Visade morfar min nya rakhyvel och han ville också ha en. Han kunde ju redan använda den så snarare jag som fick lära mig av honom. Smidigaste rakningen jag varit med om.',
    name: 'Love N.',
    rating: 4.5
  },{
    header: '"Snyggt och elegant"',
    text: 'Hyveln är ju riktigt snygg, köpte rosenträ. Kan likväl ligga framme i badrummet, det blir elegant. Skulle önska att det kom ett etui man kunde ha den i när man reser också.',
    name: 'Jonas J.',
    rating: 4
  }
  ]

  function Slideshow() {
    const [index, setIndex] = useState()

    useEffect(() => {
      setTimeout(() =>
          setIndex((prevIndex) => 
            typeof(prevIndex) === 'undefined' ? 0 :
              prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      );
      return () => {};
    }, [index]);
    return (
      <div 
          style={{
            flexDirection: 'row',
            width: '100vw',
            maxWidth: '100%',
            overflow: 'hidden'
          }} >
            <div style={{
              display: 'flex',
              transform: `translate3d(${-index * 100}%, 0, 0)`,
              transition: 'ease 1000ms'
            }} >
              {reviews.map((review, index) => (
                <div key={index} >
                  <div style={{
                      width: '100vw',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                      }} >
                    <h3 style={{
                      
                    }} >
                      {review.header}  
                    </h3>
                    <p style={{
                      textAlign: 'center',
                      width: '50vw'
                    }} >
                      {review.text}
                    </p>  
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '20%',
                      justifyContent: 'space-between',
                    }} >
                      <div style={{
                        display: 'flex'
                      }} >
                        {review.rating >= 1 ? <BsStarFill color='#257062' size={20} /> : review.rating < 1 && review.rating > 0 ? <BsStarHalf color='#257062' size={20} /> : null}  
                        {review.rating >= 2 ? <BsStarFill color='#257062' size={20} /> : review.rating < 2 && review.rating > 1 ? <BsStarHalf color='#257062' size={20} /> : null}    
                        {review.rating >= 3 ? <BsStarFill color='#257062' size={20} /> : review.rating < 3 && review.rating > 2 ? <BsStarHalf color='#257062' size={20} /> : null}  
                        {review.rating >= 4 ? <BsStarFill color='#257062' size={20} /> : review.rating < 4 && review.rating > 3 ? <BsStarHalf color='#257062' size={20} /> : null}  
                        {review.rating == 5 ? <BsStarFill color='#257062' size={20} /> : review.rating < 5 && review.rating > 4 ? <BsStarHalf color='#257062' size={20} /> : null}  
                      </div>                      
                      <p>
                        {review.name}
                      </p>
                    </div>



                  </div>

              </div>
            ))}
            </div>
          </div>
    );
  }


    return(
       <div style={{
            //display: 'flex',
            //flexDirection: DynamicSize({large: 'row', medium: 'row', mobile: 'column'}),
            //justifyContent: 'space-evenly',
            //height: '50vh',
            marginTop: 50
           }} >
          <Slideshow/>
        </div>
    )
}


export default SkaggSection4;

