import {useEffect, useState} from 'react'

import { useNavigate } from 'react-router-dom';

import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size';

import NavigatorBar from '../navigator_bar';
import Footer from '../footer';
import DynamicSize from '../functions/dynamic_size';


function AboutUs() {
  const navigate = useNavigate()
  const [senderFirstName, setSenderFirstName] = useState('')
  const [senderLastName, setSenderLastName] = useState('')
  const [senderEmail, setSenderEmail] = useState('')
  const [senderText, setSenderText] = useState('')
  const [readyToSend, setReadyToSend] = useState(false)
  
  const width = useWindowWidth()
  const breakingPoint = 800
  const mobileBreakingPoint = 500

 
  return (
     <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
     }} >
        <NavigatorBar />
        <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: 100
        }} >

            <div style={{
                width: '80%'
            }} >
                <p>
                    Skägg är mer än bara ett rakhyvelsföretag. Vi väljer att göra saker elegant. Alla våra produkter är gjorda helt i naturmaterial och våra rakhyvlar är skapade för att du ska få den absolut skarpaste rakningen. Allt som allt, du slipper plast och andra material som varken är bra för miljön eller särskilt lyxiga. Vi vill få slut på icke miljövänlig rakning som kostar skjortan och samtidigt inte ens uppfyller grundläggande krav vad gäller bekvämlighet. Vi skapar nästa generation av elegant rakning och säkerställer att den passar den upptagne mannen - precis så som din farfar hade uppskattat det.
                </p>
                <p>
                    Att välja Skägg är att välja det mer eleganta alternativet - alla kategorier. Skarp rakning. Smidig prenumeration. Hållbara produkter.
                </p>
            </div>
        </div>
        <Footer />
     </div>
  );
}

export default AboutUs;