/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_mobile_analytics_app_id": "6b9ed1122c3f47b4a05117d21410d828",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "6b9ed1122c3f47b4a05117d21410d828",
            "region": "eu-central-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://u6wlpxxgyvb7dlsivwx5dtmzme.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-bqgtzxjn35advntjri6uzaujpa",
    "aws_cognito_identity_pool_id": "eu-north-1:6c158bd2-210e-44a4-9780-9e1a5a6c808c",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_B5HYRbIpF",
    "aws_user_pools_web_client_id": "56u4364h2s6ivd3ks6okqtrh87",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "svallweb26b7ffedb1f44b38ba22e506322c706984835-prod",
    "aws_user_files_s3_bucket_region": "eu-north-1"
};


export default awsmobile;
