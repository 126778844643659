import {useEffect, useState} from 'react'

import { useNavigate } from 'react-router-dom';

import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size';

import NavigatorBar from '../navigator_bar';
import Footer from '../footer';
import DynamicSize from '../functions/dynamic_size';

const WriteMail = ({
    senderFirstName, 
    setSenderFirstName,
    senderLastName,
    setSenderLastName,
    senderEmail,
    setSenderEmail,
    senderText,
    setSenderText,
    readyToSend,
    SendMessage
        }) =>  (
    <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: 100
    }} >
        <div style={{
            height: 500,
            width: DynamicSize({large: '40vw', medium: '60vw', mobile: '95vw' }),
            maxWidth: 800,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }} >
            <div style={{
                display: 'flex',
                flexDirection: DynamicSize({mobile: 'column'}),
                justifyContent: 'space-between',
                width: '100%',
            }} >
                <div style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderRadius: 10,
                    width: DynamicSize({large: '48%', medium: '48%', mobile:'100%'}),
                    height: 50,
                    display: 'flex',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    marginBottom: '20px',
                    justifyContent: 'center'
                }} >
                    <input style={{
                        width: '95%',
                        borderColor: 'transparent',
                        outline: 'none',
                        fontSize: 20
                        }}
                        type={'text'}
                        placeholder={'Namn*'}
                        value={senderFirstName}
                        onChange={(e) => setSenderFirstName(e.target.value)}
                        autoComplete={'given-name'}
                         />
                </div>
                <div style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderRadius: 10,
                    width: DynamicSize({large: '48%', medium: '48%', mobile: '100%'}),
                    height: 50,
                    display: 'flex',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    marginBottom: '20px',
                    justifyContent: 'center'
                }} >
                    <input style={{
                        width: '95%',
                        borderColor: 'transparent',
                        outline: 'none',
                        fontSize: 20
                        }}
                        type={'text'}
                        placeholder={'Efternamn*'}
                        value={senderLastName}
                        onChange={(e) => setSenderLastName(e.target.value)}
                        autoComplete={'family-name'}
                         />
                </div>
            </div>
            <div style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderRadius: 10,
                    width: '100%',
                    height: 50,
                    display: 'flex',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    marginBottom: '20px',
                    justifyContent: 'center'
                }} >
                    <input style={{
                        width: '95%',
                        borderColor: 'transparent',
                        outline: 'none',
                        fontSize: 20
                        }}
                        type={'email'}
                        placeholder={'Email*'}
                        value={senderEmail}
                        onChange={(e) => setSenderEmail(e.target.value)}
                        autoComplete={'email'}
                         />
                </div>
                <div style={{
                        borderStyle: 'solid',
                        borderColor: 'black',
                        borderRadius: 10,
                        width: '100%',
                        height: 400,
                        display: 'flex',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        marginBottom: '20px',
                        justifyContent: 'center'
                    }} >
                        <input style={{
                            width: '95%',
                            borderColor: 'transparent',
                            outline: 'none',
                            fontSize: 20
                            }}
                            type={'text'}
                            placeholder={'Meddelande*'}
                            value={senderText}
                            onChange={(e) => setSenderText(e.target.value)}
                            />
                    </div>
            <div style={{
                height: 50,
                width: '50%',
                backgroundColor: readyToSend ? '#257062' : 'grey',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                boxShadow: `1px 2px 5px #000`,
            }}
            onClick={() => SendMessage()} >
                <p style={{
                    color: 'white'
                }} >
                    SKICKA IN
                </p>
            </div>
        </div>

    </div>
)

function Contact() {
  const navigate = useNavigate()
  const [senderFirstName, setSenderFirstName] = useState('')
  const [senderLastName, setSenderLastName] = useState('')
  const [senderEmail, setSenderEmail] = useState('')
  const [senderText, setSenderText] = useState('')
  const [readyToSend, setReadyToSend] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  
  const width = useWindowWidth()
  const breakingPoint = 800
  const mobileBreakingPoint = 500

  async function SendMessage() {
    if (!readyToSend) {
        return
    }
    //const url ='https://rrxthtzbpjdp2zplerdbn42seq0bsyce.lambda-url.eu-north-1.on.aws/' //staging
    const url ='https://6j5zsarg7sr6z35olidoy4twhy0csfge.lambda-url.eu-north-1.on.aws/' //prod
    const response = await fetch(
        url+'?senderFirstName='+senderFirstName+'&senderLastName='+senderLastName+'&senderEmail='+senderEmail+'&senderText='+senderText
    )
    setSenderFirstName('')
    setSenderLastName('')
    setSenderEmail('')
    setSenderText('')
    setShowConfirmation(true)
    setReadyToSend(false)
  }

  useEffect(() => {
    if (
        senderFirstName.length > 0 && 
        senderLastName.length > 0 &&
        senderEmail.length > 0 &&
        senderText.length > 0
        ) {
            setReadyToSend(true)
        }
    else {
        setReadyToSend(false)
    }
  }, [senderFirstName, senderLastName, senderEmail, senderText])

 

  return (
     <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
     }} >
        <NavigatorBar />
        {showConfirmation ? 
            <div style={{
                height: '50vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }} >
                <p>
                    Tack! Vi på Skägg Razors har tagit emot ditt meddelande och hör av oss så snart vi kan.
                </p>
            </div>
            :
            <WriteMail 
                senderFirstName={senderFirstName} 
                setSenderFirstName={setSenderFirstName}
                senderLastName={senderLastName}
                setSenderLastName={setSenderLastName}
                senderEmail={senderEmail}
                setSenderEmail={setSenderEmail}
                senderText={senderText}
                setSenderText={setSenderText}
                readyToSend={readyToSend}
                SendMessage={SendMessage} />
        }
        <Footer />
     </div>
  );
}

export default Contact;