import {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
//import logo from '../assets/SVALL_LOGO.svg';

//import logo from './logo.svg';
import { API } from "aws-amplify";
import * as queries from '../../graphql/queries';

import { useNavigate, useLocation } from 'react-router-dom';

import NavigatorBar from '../navigator_bar';
import ProductBox from '../product_box';
import Footer from '../footer';
import DynamicSize from '../functions/dynamic_size';

function Products() {
  const navigate = useNavigate()
  const location = useLocation()
  const [products, setProducts] = useState()
  const [startKitProducts, setStartKitProducts] = useState()
  const [additionalProducts, setAdditionalProducts] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [cookie, setCookie, removeCookie] = useCookies(['shoppingBag', 'product'])

  async function GetProducts() {
    const fetchProducts = await API.graphql({
      query: queries.listProducts
    });
    console.log('f', fetchProducts)
    const sortedProducts = fetchProducts.data.listProducts.items.sort((a, b) => a.productNumber - b.productNumber)
    setProducts(sortedProducts)
    //setStartKitProducts(sortedProducts.filter((product) => product.CATEGORY === 'START_KIT'))
    //setAdditionalProducts(sortedProducts.filter((product) => product.CATEGORY === 'SUBSCRIPTION'))
    setSelectedProduct(sortedProducts.filter((product) => product.CATEGORY === location.state.types[0].CATEGORY)[0].id)
  }

  useEffect(() => {
    GetProducts()
  }, [])

  function Click() {
    if (!selectedProduct) {
      return;
    }
    let changeOrder = false
    if (cookie.product) {
      cookie.product.map(p => {
        if (JSON.parse(p).product === selectedProduct) {
          changeOrder = true
          return;
        }
      })
    }
    if (!changeOrder) {
      const product = {
        product: selectedProduct,
        frequency: 0
      }
      if (location.state.types[0].CATEGORY === 'SEASONAL') {
        setCookie('shoppingBag', cookie.product ? [...cookie.product, JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'})
      }
      setCookie('product', cookie.product ? [...cookie.product, JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'})
    }
    if (location.state.types[0].CATEGORY === 'SEASONAL') {
      removeCookie('futureShoppingBag', {path: '/'})
      navigate('/checkout')
    }
    else {
      navigate('/get-started/step-2')
    }
  }

  return (
    <div 
    //className='App'
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw'
    }}
    >
      <NavigatorBar />
      {!products ? null :
        location.state.types.map(type => (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#f0efeb',
            //justifyContent: 'center',
            marginBottom: '5vh',
            paddingBottom: '5vh'
            //width: '100vw'
          }}
          key={type.HEADER} >
            <h3 style={{
              color: 'black',
              fontSize: '10vw',
              fontFamily: 'Prompt',
            }} >
              {type.HEADER}
            </h3>
            <h3 style={{
              color: 'black',
              fontSize: '5vw',
              marginBottom: '5vh',
              fontFamily: 'Prompt',
              fontWeight: '200'
            }} >
              {type.TEXT}
            </h3>
            <div 
            className="scrollbar"
            style={{
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'scroll',
              width: '100vw',
              maxWidth: '100%',
              scrollbarWidth: 0,
              marginBottom: '5vh',
            }} >
              {products.filter((product) => product.CATEGORY === type.CATEGORY).map((product, index) => (
                  <ProductBox
                    key={product.id}
                    id={product.id}
                    price={product.PRICE}
                    name={product.NAME}
                    s3file={product.S3FILE}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    index={index}
                    length={products.filter((product) => product.CATEGORY === type.CATEGORY).length} />
              )
              )}
            </div>
  
            <div style={{
              backgroundColor: '#257062',
              height: '60px',
              width: '300px',
              maxWidth: '90vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: `1px 2px 5px #000`,
              cursor: 'pointer'
            }}
              onClick={() => Click()} >
              <h3 style={{
                textTransform: 'uppercase',
                fontFamily: 'Prompt',
                color: 'white'
              }} >
                Nästa steg
              </h3>
            </div>
  
          </div>

        ))
      }
     
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }} >
        <p style={{
          fontWeight: 'bold',
          fontFamily: 'Prompt'
        }} >
          Säkerhetshyveln - använd av barberare sedan 1700-talet
        </p>
        <div style={{
            width: DynamicSize({large: '70vw', medium: '80vw', mobile: '90vw'}),
            maxWidth: 2000
          }} >
          <p style={{
            fontFamily: 'Prompt',
            fontWeight: '200'
          }} >
            En säkerhetshyvel är ett rakredskap med en skyddsanordning placerad mellan kanten på bladet och huden. Det ursprungliga syftet med dessa skyddsanordningar var att minska den skicklighet som krävs för att raka utan skador och därigenom minska beroendet av professionella barberare.
          </p>
          <p style={{
            fontFamily: 'Prompt',
            fontWeight: '200'
          }} >
            Skyddsanordningar för rakhyvlar har funnits sedan åtminstone 1700-talet. Den första kända förekomsten av termen "säkerhetshyvel" finns i ett patent från 1880 för en rakhyvel i den grundläggande samtida konfigurationen med ett handtag i vilket ett avtagbart blad är placerat (även om denna form föregick patentet).
          </p>
        </div>
        <img 
          src="assets/razors/IMG_5459.jpeg" 
          alt='Klassisk rakhyvel och rakblad'
          style={{
            width: '100vw'
          }}
          />
          
      </div>

      <Footer />
    </div>
  );
}



export default Products;