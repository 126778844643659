import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size'

function DynamicSize({large, medium, mobile}) {
        const width = useWindowWidth()
        const breakingPoint = 1200
        const mobileBreakingPoint = 600

        if (width > breakingPoint) {
            return(
                large
            )
        }
        else if (width > mobileBreakingPoint) {
            //const s = size/13
            return(
                medium
            )
        }
        else {
            //const s = size/15
            return(
                mobile
            )
        }

    }
export default DynamicSize;