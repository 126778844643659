import {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { API } from "aws-amplify";
import * as queries from '../../graphql/queries'

import SimpleNavigatorBar from '../simple_navigator_bar';
import DynamicSize from '../functions/dynamic_size';
import ProductBox from '../product_box';

function OrderStep1() {
    const navigate = useNavigate()
    const [products, setProducts] = useState()
    const [selectedProduct, setSelectedProduct] = useState()
    const [cookie, setCookie, removeCookie] = useCookies(['shoppingBag', 'product', 'futureShoppingBag'])

    async function GetProducts() {
        const fetchProducts = await API.graphql({
          query: queries.listProducts, variables: {filter: {CATEGORY: {eq: "START_KIT"}}}
        });
        const sortedProducts = fetchProducts.data.listProducts.items.sort((a, b) => a.productNumber - b.productNumber)
        setProducts(sortedProducts)
        setSelectedProduct(sortedProducts[0].id)
      }
    
      useEffect(() => {
        GetProducts()
      }, [])
    
      function Click() {
        if (!selectedProduct) {
            return;
        }
        let changeOrder = false
        if (cookie.product) {
          cookie.product.map(p => {
            if (JSON.parse(p).product === selectedProduct) {
              changeOrder = true
              return;
            }
          })
        }
        if (!changeOrder) {
          const product = {
            product: selectedProduct,
            frequency: 0
          }
          setCookie('product', cookie.product ? [...cookie.product, JSON.stringify(product)] : [JSON.stringify(product)], {path: '/'})
        }
        navigate('/get-started/step-2')
      }

      return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            //justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            
        }} >
            <SimpleNavigatorBar />
            
            <div style={{
                width: '100vw',
                //minWidth: 400,
                display: 'flex',
                flexDirection: 'column',
            }} >
            
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                
                }} >
                <p style={{
                    maxWidth: '100%',
                    
                }} >
                    Steg 1/3
                </p>
                <p>
                    Välj en rakhyvel
                </p>
                
            </div>
            {!products ? null :
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '5vh',
                    paddingBottom: '5vh',
                    }} >
                    <div 
                      className="scrollbar"
                      style={{
                          display: 'flex',
                          flexDirection: 'row',
                          overflowX: 'scroll',
                          width: '100vw',
                          maxWidth: '100%',
                          scrollbarWidth: 0,
                          marginBottom: '5vh',
                    }} >
                        {products.map((product, index) => 
                        <ProductBox 
                            key={product.id}
                            id={product.id}
                            price={product.PRICE}
                            name={product.NAME}
                            s3file={product.S3FILE}
                            selectedProduct={selectedProduct} 
                            setSelectedProduct={setSelectedProduct}
                            index={index}
                            length={products.length} />
                        )}
                    </div>
                    </div>
            }
            <div 
                onClick={() => Click()}
                style={{
                    backgroundColor: '#257062',
                    width: DynamicSize({large: '90%', medium: '70%', mobile: '40%'}),
                    maxWidth: 500,
                    marginTop: 15,
                    height: 70,
                    display: 'flex',
                    alignSelf: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20vh',
                    cursor: 'pointer',
                    boxShadow: `1px 2px 5px #000`,
                }} >
                <p style={{
                    color: 'white',
                    fontSize: 20
                }} >Nästa steg</p>
            </div>
            </div>
        </div>
      )
}
export default OrderStep1;